import { Table, TableProps } from 'antd'
import cn from 'classnames'
import { useMemo } from 'react'

import { documentsItem, loadingStatus, useAppDispatch } from '@/store'

import { openErrorNotification } from '@/utils'

import { terminalAPI } from '@/api'

export function TerminalDocsAppeal({
	data,
	className,
}: {
	data: Array<documentsItem>
	className?: string
}) {
	const dispatch = useAppDispatch()

	const getDoc = async (id: string, fileName: string, type: string) => {
		try {
			dispatch(loadingStatus(true))
			const { data } = await terminalAPI.getDocument({ DocumentId: id })
			const blob = new Blob([data])
			const link = document.createElement('a')
			const blobUrl = window.URL.createObjectURL(blob)
			link.href = blobUrl
			link.download = `${fileName}${type}`
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		} catch (e) {
			console.error(e)
			openErrorNotification('Ошибка открытия документа')
		} finally {
			dispatch(loadingStatus(false))
		}
	}

	const columns: TableProps<documentsItem>['columns'] = useMemo(
		() => [
			{
				title: 'Название',
				render: (obj) => {
					return (
						<button onClick={() => getDoc(obj.id, obj.fileName, obj.type)}>
							{obj.fileName}
						</button>
					)
				},
			},
		],
		[getDoc]
	)

	return (
		<div className={cn('terminal__docs-section', className)}>
			<h2>Документы за сеанс</h2>
			<Table
				className="terminal__appeals-table"
				columns={columns}
				dataSource={data} // Копируем массив для гарантии рендеринга
				rowKey="id"
			/>
		</div>
	)
}
