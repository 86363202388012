import { PlusOutlined } from '@ant-design/icons'
import { Button, Input, Modal, Upload, UploadFile, UploadProps } from 'antd'
import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import {
	ChangePasswordOperator,
	LocationSelect,
	RemoveOperator,
	SwitchBanOperator,
	TerminalsSelect,
} from '@/components'

import {
	getAboutUser,
	getUser,
	loadingStatus,
	updateUser,
	useAppDispatch,
	useAppSelector,
} from '@/store'

import {
	ADMIN_PATH,
	emailField,
	loginField,
	openErrorNotification,
	openSuccessNotification,
} from '@/utils'

import { terminalAPI, usersAPI } from '@/api'

import Avatar from '../assets/images/avatarka.png'

type Inputs = {
	userName: string
	login: string
	email: string
	id: string
}

export function UserPage() {
	const aboutUser = useAppSelector((state) => state.users.aboutUser)
	const curentUser = useAppSelector((state) => state.users.currentUser)
	const [changePasModalIsOpen, setChangePasModalIsOpen] = useState(false)
	const [removeModalIsOpen, setRemoveModalIsOpen] = useState(false)
	const [terminalsString, setTerminalsstring] = useState<string[] | null>(null)
	const [trigger, setTrigger] = useState(0)
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	const params = useParams()
	const id = params.id

	const [fileList, setFileList] = useState<UploadFile[]>([
		{
			uid: '-1',
			name: 'image.png',
			status: 'done',
			url: Avatar,
		},
	])

	const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
		setFileList(newFileList)

	const onSend = async (file: any, fileList: any) => {
		try {
			dispatch(loadingStatus(true))
			if (id && curentUser?.id) {
				await usersAPI.changeFoto({ userId: id, photo: file })
				dispatch(getAboutUser({ UserId: id }))
				dispatch(getUser({ UserId: curentUser.id }))
			}

			openSuccessNotification('Успешная загрузка фото')
		} catch (e) {
			console.error(e)
			openErrorNotification('Ошибка загрузки фото')
		} finally {
			dispatch(loadingStatus(false))
		}
		return false
	}

	const onDelete = async () => {
		try {
			dispatch(loadingStatus(true))
			if (id && curentUser?.id) {
				// await usersAPI.changeFoto({ userId: id, photo: file })
			}
			navigate(ADMIN_PATH)
			openSuccessNotification('Успешное удаление пользователя')
		} catch (e) {
			console.error(e)
			openErrorNotification('Ошибка удаления пользователя')
		} finally {
			dispatch(loadingStatus(false))
		}
	}

	const closeModal = () => {
		setChangePasModalIsOpen(false)
		setRemoveModalIsOpen(false)
	}

	const uploadButton = (
		<button
			className="operators__avatar-new"
			style={{ border: 0, background: 'none' }}
			type="button"
		>
			<PlusOutlined />
			<div style={{ marginLeft: '5px' }}>Загрузить новое</div>
		</button>
	)

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm<Inputs>()
	const onSubmit: SubmitHandler<Inputs> = async (data) => {
		if (terminalsString && terminalsString?.length >= 0 && id) {
			try {
				dispatch(loadingStatus(true))
				aboutUser?.role !== 'Statistician' &&
					aboutUser?.role !== 'BillingSync' &&
					(await terminalAPI.setUserTerminals({
						userId: id,
						terminalsId: terminalsString,
					}))
				dispatch(
					updateUser({
						UserId: data.id,
						userName: data.userName,
						login: data.login,
						email: data?.email,
					})
				)
			} catch (e: any) {
				console.error(e)
				if (e?.response?.data?.message) {
					openErrorNotification(e.response.data.message)
				} else openErrorNotification('Ошибка изменения терминалов пользователя')
			} finally {
				setTrigger((prev) => prev + 1)
				await loadUserTerminals(id)
				dispatch(loadingStatus(false))
			}
		} else {
			dispatch(
				updateUser({
					UserId: data.id,
					userName: data.userName,
					login: data.login,
					email: data?.email,
				})
			)
		}
	}

	const fetch = () => {
		if (id) {
			dispatch(getAboutUser({ UserId: id }))
		}
	}

	useEffect(() => {
		if (id) {
			dispatch(getAboutUser({ UserId: id }))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const loadAvatar = async (photoId: string) => {
		try {
			dispatch(loadingStatus(true))
			const { data } = await usersAPI.getFoto({
				PhotoId: photoId,
			})
			const blob = new Blob([data])
			setFileList([
				{
					uid: '-1',
					name: 'image.png',
					status: 'done',
					url: URL.createObjectURL(blob),
				},
			])
		} catch (e) {
			console.error(e)
			openErrorNotification('Ошибка получения фото')
		} finally {
			dispatch(loadingStatus(false))
		}
	}
	const loadUserTerminals = async (id: string) => {
		const userTerminals = await terminalAPI.getList({ UserId: id })
		setTerminalsstring(userTerminals.data.map((e: any) => e.id))
	}

	useEffect(() => {
		reset({
			id: id,
			login: aboutUser?.login,
			userName: aboutUser?.userName,
			email: aboutUser?.email,
		})
		if (aboutUser?.photoId) {
			loadAvatar(aboutUser.photoId)
		} else {
			setFileList([
				{
					uid: '-1',
					name: 'image.png',
					status: 'done',
					url: Avatar,
				},
			])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [aboutUser])

	useEffect(() => {
		if (id) {
			loadUserTerminals(id)
		}
	}, [id])

	return (
		<div className="operators">
			<Button className="operators__back" onClick={() => navigate(-1)}>
				Назад
			</Button>
			<div className="operators__title title">Обзор пользователя</div>
			<form className="operators__wrap" onSubmit={handleSubmit(onSubmit)}>
				<div className="operators__top">
					<div className="operators__avatar">
						<Upload
							beforeUpload={(file, fileList) => onSend(file, fileList)}
							listType="picture-card"
							fileList={fileList}
							onChange={handleChange}
							maxCount={1}
							onRemove={async (e) => {
								try {
									await usersAPI.deletePhoto({ appUserId: id! })
									dispatch(getAboutUser({ UserId: id! }))
									openSuccessNotification('Фото удалено')
									return true
								} catch (error) {
									return false
								}
							}}
							showUploadList={{
								showPreviewIcon: false,
							}}
						>
							{fileList.length >= 8 ? null : uploadButton}
						</Upload>
					</div>
					<div className="operators__main">
						<div className="operators__input-wrap">
							<div className="input">
								<label className="input__label" htmlFor="userName">
									Имя
								</label>
								<Controller
									name="userName"
									control={control}
									rules={loginField}
									render={({ field }) => (
										<Input
											placeholder="Имя пользователя"
											id="userName"
											{...field}
										/>
									)}
								/>
								{errors?.userName && (
									<div className="input__error">
										{errors.userName?.message
											? errors.userName?.message
											: 'Ошибка'}
									</div>
								)}
							</div>
						</div>
						<div className="operators__input-wrap">
							<div className="input">
								<label className="input__label" htmlFor="login">
									Логин
								</label>
								<Controller
									name="login"
									disabled
									control={control}
									rules={loginField}
									render={({ field }) => (
										<Input placeholder="Логин" id="login" {...field} />
									)}
								/>
								{errors?.login && (
									<div className="input__error">
										{errors.login?.message ? errors.login?.message : 'Ошибка'}
									</div>
								)}
							</div>
						</div>
						<div className="operators__input-wrap">
							<div className="input">
								<label className="input__label" htmlFor="login">
									Почта
								</label>
								<Controller
									name="email"
									control={control}
									render={({ field }) => (
										<Input
											placeholder="Почта"
											id="email"
											type="email"
											{...field}
										/>
									)}
								/>
								{errors?.email && (
									<div className="input__error">
										{errors.email?.message ? errors.email?.message : 'Ошибка'}
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="operators__btns">
						{aboutUser?.role !== 'Admin' && aboutUser && (
							<SwitchBanOperator
								userId={aboutUser?.id}
								ban={aboutUser.isBlocked}
								fetch={fetch}
							/>
						)}

						<Button
							className="operators__btn-pass-del"
							type="primary"
							size="large"
							htmlType="button"
							onClick={() => setChangePasModalIsOpen(true)}
						>
							Сбросить пароль
						</Button>
						<Button
							className="operators__btn-del"
							danger
							disabled
							type="primary"
							size="large"
							htmlType="button"
							onClick={() => setRemoveModalIsOpen(true)}
						>
							Удалить
						</Button>
					</div>
				</div>
				<div className="operators__center">
					{aboutUser && (
						<LocationSelect
							currentLocation={aboutUser?.location}
							userId={aboutUser?.id}
						/>
					)}
					{aboutUser &&
						aboutUser.role !== 'Statistician' &&
						aboutUser.role !== 'BillingSync' && (
							<TerminalsSelect
								setTerminalsstring={setTerminalsstring}
								terminals={terminalsString}
								userId={aboutUser.id}
								trigger={trigger}
							/>
						)}
				</div>
				<button className="operators__btn-save btn">Сохранить</button>
			</form>
			<Modal
				title="Изменение пароля"
				open={changePasModalIsOpen}
				onOk={closeModal}
				onCancel={closeModal}
				footer={null}
			>
				{aboutUser && (
					<ChangePasswordOperator
						onClose={closeModal}
						name={aboutUser?.userName}
						userId={aboutUser.id}
					/>
				)}
			</Modal>
			<Modal
				title="Удаление пользователя"
				open={removeModalIsOpen}
				onOk={closeModal}
				onCancel={closeModal}
				footer={null}
			>
				{aboutUser && (
					<RemoveOperator
						onClose={closeModal}
						onDelete={onDelete}
						name={aboutUser?.userName}
					/>
				)}
			</Modal>
		</div>
	)
}
