import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { Button, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { loadingStatus } from 'store/app'
import { useAppDispatch } from 'store/hooks'

import {
	emailField,
	emailFieldNotRequired,
	numberField,
	openErrorNotification,
	openSuccessNotification,
	passField,
	requiredField,
	settingEmailType,
	textField,
} from '@/utils'

import { emailConfigAPI } from '@/api'

export default function EmailConfig() {
	const [settingEmail, setSettingEmail] = useState<null | settingEmailType>(
		null
	)
	const dispatch = useAppDispatch()

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm<settingEmailType>()

	const onSubmit: SubmitHandler<settingEmailType> = async (data) => {
		dispatch(loadingStatus(true))
		try {
			await emailConfigAPI.editEmailConfig(data)
			setSettingEmail(data)
			openSuccessNotification('Данные сохранены')
			fetch()
		} catch (e) {
			console.error(e)
			openErrorNotification('Ошибка сохранения')
		} finally {
			dispatch(loadingStatus(false))
		}
	}

	const fetch = async () => {
		dispatch(loadingStatus(true))
		try {
			const { data } = await emailConfigAPI.getEmailConfig()
			setSettingEmail(data)
		} catch (e) {
			console.error(e)
		} finally {
			dispatch(loadingStatus(false))
		}
	}

	useEffect(() => {
		fetch()
	}, [])

	useEffect(() => {
		if (settingEmail) {
			reset(settingEmail)
		}
	}, [settingEmail])

	return (
		<div className="settings__email">
			<div className="settings__email-title _h4">Настройки почты</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="settings__email-block">
					<div className="settings__email-block-title">Основное</div>
					<div className="settings__email-inputs-inner">
						<div className="settings__email-row">
							<div className="settings__email-input-wrap">
								<label
									htmlFor="senderAddress"
									className="settings__email-lable"
								>
									Адрес исходящей почты
								</label>
								<Controller
									name="senderAddress"
									control={control}
									rules={emailField}
									render={({ field }) => (
										<Input
											placeholder="test@test.ru"
											id="senderAddress"
											className="settings__email-input"
											{...field}
										/>
									)}
								/>

								{errors?.senderAddress && (
									<div className="input__error">
										{errors.senderAddress?.message
											? errors.senderAddress?.message
											: 'Ошибка'}
									</div>
								)}
							</div>
						</div>
						<div className="settings__email-row">
							<div className="settings__email-input-wrap">
								<label htmlFor="password" className="settings__email-lable">
									Пароль исходящей почты
								</label>
								<Controller
									name="password"
									control={control}
									rules={passField}
									render={({ field }) => (
										<Input.Password
											placeholder="Пароль"
											id="password"
											className="settings__email-input"
											iconRender={(visible) =>
												visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
											}
											{...field}
										/>
									)}
								/>

								{errors?.password && (
									<div className="input__error">
										{errors.password?.message
											? errors.password?.message
											: 'Ошибка'}
									</div>
								)}
							</div>
						</div>
						<div className="settings__email-row">
							<div className="settings__email-input-wrap">
								<label htmlFor="host" className="settings__email-lable">
									Сервер исходящей почты
								</label>
								<Controller
									name="host"
									control={control}
									rules={requiredField}
									render={({ field }) => (
										<Input
											placeholder="test@test.ru"
											id="host"
											className="settings__email-input"
											{...field}
										/>
									)}
								/>

								{errors?.host && (
									<div className="input__error">
										{errors.host?.message ? errors.host?.message : 'Ошибка'}
									</div>
								)}
							</div>
							<div className="settings__email-input-wrap">
								<label htmlFor="port" className="settings__email-lable">
									Порт
								</label>
								<Controller
									name="port"
									control={control}
									rules={numberField}
									render={({ field }) => (
										<Input
											placeholder="Порт"
											id="port"
											className="settings__email-input"
											{...field}
										/>
									)}
								/>

								{errors?.port && (
									<div className="input__error">
										{errors.port?.message ? errors.port?.message : 'Ошибка'}
									</div>
								)}
							</div>
						</div>
						<div className="settings__email-row">
							<div className="settings__email-input-wrap">
								<label
									htmlFor="senderAddress"
									className="settings__email-lable"
								>
									Почта для обратных звонков
								</label>
								<Controller
									name="callbacksAddress"
									control={control}
									rules={emailField}
									render={({ field }) => (
										<Input
											placeholder="test@test.ru"
											id="callbacksAddress"
											className="settings__email-input"
											{...field}
										/>
									)}
								/>

								{errors?.callbacksAddress && (
									<div className="input__error">
										{errors.callbacksAddress?.message
											? errors.callbacksAddress?.message
											: 'Ошибка'}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
				{/* <div className="settings__email-block">
					<div className="settings__email-block-title">Дополнительно</div>
					<div className="settings__email-inputs-inner">
						<div className="settings__email-row">
							<div className="settings__email-input-wrap">
								<label
									htmlFor="paymentsAddress"
									className="settings__email-lable"
								>
									Адрес для отправки платежей
								</label>
								<Controller
									name="paymentsAddress"
									control={control}
									rules={emailFieldNotRequired}
									render={({ field }) => (
										<Input
											placeholder="test@test.ru"
											id="paymentsAddress"
											className="settings__email-input"
											{...field}
										/>
									)}
								/>

								{errors?.paymentsAddress && (
									<div className="input__error">
										{errors.paymentsAddress?.message
											? errors.paymentsAddress?.message
											: 'Ошибка'}
									</div>
								)}
							</div>
						</div>
						<div className="settings__email-row">
							<div className="settings__email-input-wrap">
								<label
									htmlFor="readingsAddress"
									className="settings__email-lable"
								>
									Адрес для отправки показаний
								</label>
								<Controller
									name="readingsAddress"
									control={control}
									rules={emailFieldNotRequired}
									render={({ field }) => (
										<Input
											placeholder="test@test.ru"
											id="readingsAddress"
											className="settings__email-input"
											{...field}
										/>
									)}
								/>

								{errors?.readingsAddress && (
									<div className="input__error">
										{errors.readingsAddress?.message
											? errors.readingsAddress?.message
											: 'Ошибка'}
									</div>
								)}
							</div>
						</div>
					</div>
				</div> */}

				<Button
					className="settings__email-btn"
					type="primary"
					htmlType="submit"
				>
					Сохранить
				</Button>
			</form>
		</div>
	)
}
