import { Button, TimePicker, notification } from 'antd'
import { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { Loading } from '@/components'

import { requiredField } from '@/utils'

import { devicesAPI } from '@/api'

interface Props {
	TerminalId: string
}

interface form {
	timeAt: Dayjs
	timeTo: Dayjs
}

export const SettingAcquiringModal = ({ TerminalId }: Props) => {
	const [loading, setLoading] = useState<boolean>(true)
	const [status, setStatus] = useState<boolean>()

	useEffect(() => {
		const fetch = async () => {
			setLoading(true)
			try {
				const {
					data: { isClosed },
				} = await devicesAPI.get_shift_status({
					TerminalId,
				})
				setStatus(isClosed)
			} catch (error: any) {
				console.debug(error)
				notification.error({ message: error.message })
			} finally {
				setLoading(false)
			}
		}

		fetch()
	}, [])

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm<form>({})

	const onSubmit: SubmitHandler<form> = (data) => {
		console.debug(
			data.timeAt.format('HH:mm:ss'),
			data.timeTo.format('HH:mm:ss')
		)
	}

	const close = async () => {
		setLoading(true)
		try {
			await devicesAPI.open_shift({
				TerminalId,
			})
			const {
				data: { isClosed },
			} = await devicesAPI.get_shift_status({
				TerminalId,
			})
			setStatus(isClosed)
			notification.success({ message: 'Успешно' })
		} catch (error: any) {
			console.debug(error)
			notification.error({ message: error.message })
		} finally {
			setLoading(false)
		}
	}

	const handleOpen = async () => {
		setLoading(true)
		try {
			await devicesAPI.close_shift({
				TerminalId,
			})
			const {
				data: { isClosed },
			} = await devicesAPI.get_shift_status({
				TerminalId,
			})
			setStatus(isClosed)
			notification.success({ message: 'Успешно' })
		} catch (error: any) {
			console.debug(error)
			notification.error({ message: error.message })
		} finally {
			setLoading(false)
		}
	}

	if (loading) {
		return (
			<div style={{ height: 200 }}>
				<Loading />
			</div>
		)
	}

	return (
		<div className="setting-acquiring">
			<form
				onSubmit={handleSubmit(onSubmit)}
				className="setting-acquiring__form"
			>
				{status ? (
					<Button
						onClick={close}
						className="setting-acquiring__btn"
						type="primary"
						htmlType="button"
						size="large"
					>
						Открыть смену
					</Button>
				) : (
					<Button
						onClick={handleOpen}
						className="setting-acquiring__btn"
						type="primary"
						htmlType="button"
						size="large"
					>
						Закрыть смену
					</Button>
				)}
				<div className="setting-acquiring__form-item">
					<div className="setting-acquiring__form-item-title">
						Время открытия смены по расписанию
					</div>
					<Controller
						name="timeAt"
						control={control}
						rules={requiredField}
						render={({ field }) => (
							<TimePicker
								value={field.value}
								onChange={(momentValue) => field.onChange(momentValue)}
								format="HH:mm"
								needConfirm={false}
							/>
						)}
					/>
					{errors.timeAt && (
						<div className="input__error">
							{errors.timeAt.message || 'Ошибка'}
						</div>
					)}
				</div>
				<div className="setting-acquiring__form-item">
					<div className="setting-acquiring__form-item-title">
						Время закрытия смены по расписанию
					</div>
					<Controller
						name="timeTo"
						control={control}
						rules={requiredField}
						render={({ field }) => (
							<TimePicker
								value={field.value}
								onChange={(momentValue) => field.onChange(momentValue)}
								format="HH:mm"
								needConfirm={false}
							/>
						)}
					/>
					{errors.timeTo && (
						<div className="input__error">
							{errors.timeTo.message || 'Ошибка'}
						</div>
					)}
				</div>
				<Button
					className="setting-acquiring__btn"
					type="primary"
					htmlType="submit"
					size="large"
				>
					Сохранить
				</Button>
			</form>
		</div>
	)
}
