import { Switch } from 'antd'
import { useEffect, useState } from 'react'

import { setStatus } from '@/store'
import { getUser, useAppDispatch, useAppSelector } from '@/store'

import { openErrorNotification } from '@/utils'

import { usersAPI } from '@/api'

export function SwitchStatus({ userId }: { userId: string }) {
	const { myStatus } = useAppSelector((state) => state.auth)
	const dispatch = useAppDispatch()
	const [switchIsActive, setSwitch] = useState(false)

	const onChange = async (checked: boolean) => {
		try {
			setSwitch(checked)
			dispatch(setStatus(checked))
			await usersAPI.switchStatus(checked)
		} catch (e: any) {
			if (e.response.data) {
				openErrorNotification('Ошибка получения информации о пользователе')
			}
		}
		dispatch(getUser({ UserId: userId }))
	}

	useEffect(() => {
		dispatch(getUser({ UserId: userId }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		setSwitch(myStatus || false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [myStatus])

	return (
		<div className="aside__top-status">
			<span className="aside__top-status-text">Статус</span>
			<Switch checked={switchIsActive} onChange={onChange} />
		</div>
	)
}
