import { Button, notification } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
	setAppealId,
	setCallRecordId,
	setIsCalling,
	setTypeCall,
	useAppDispatch,
	useAppSelector,
} from '@/store'

import { ICallBody, checkMedia } from '@/utils'

import { API_URL } from '@/configs'

import { terminalCallAPI } from '@/api'

interface Props {
	children: JSX.Element
}

export const CallProvider = ({ children }: Props) => {
	const { userId } = useAppSelector((state) => state.auth)
	const { isCalling } = useAppSelector((state) => state.call)
	const dispatch = useAppDispatch()
	const router = useNavigate()
	const notificationRef = useRef<any>(null)
	const [audio] = useState(new Audio('/call-sound.mp3'))
	const isProcessingCall = useRef(false)

	useEffect(() => {
		if (Notification.permission === 'default') {
			Notification.requestPermission()
		}

		const socket = new WebSocket(
			`${API_URL}/terminal_call/subscribe?UserId=${userId}`
		)

		socket.onmessage = async (msg: any) => {
			if (msg.data !== 'null') {
				const data: ICallBody = JSON.parse(msg.data)

				if (isProcessingCall.current || isCalling || notificationRef.current) {
					return
				}

				isProcessingCall.current = true
				audio.loop = true

				try {
					await audio.play()
				} catch (error) {
					console.error('Ошибка при воспроизведении звука:', error)
				}

				dispatch(setIsCalling(true))
				dispatch(setTypeCall('terminal'))

				sendSystemNotification(data)
				sendPushNotification(data)

				notificationRef.current = notification.open({
					key: 'call_notification',
					message: 'Звонок поступил!',
					description: (
						<div>
							<p>
								У вас новый звонок от <b>{data.terminal.name}</b>
							</p>
							<p>
								<b>{data.terminal.address}</b>
							</p>
							<p>Хотите принять или отклонить?</p>
						</div>
					),
					btn: (
						<>
							<Button
								key="accept"
								type="primary"
								onClick={() => handleAccept(data.terminal.id)}
							>
								Принять
							</Button>
							<Button key="reject" onClick={handleReject}>
								Отклонить
							</Button>
						</>
					),
					onClose: resetCallStates,
					duration: 0,
				})
			} else {
				resetCallStates()
				audio.pause()
			}
		}

		return () => socket.close()
	}, [isCalling, userId, dispatch])

	const resetCallStates = () => {
		isProcessingCall.current = false
		notificationRef.current = null
		dispatch(setIsCalling(false))
		notification.destroy('call_notification')
		closePushNotification()
	}

	const handleAccept = async (id: string) => {
		const hasMedia = await checkMedia()
		if (!hasMedia) {
			handleReject()
			return
		}

		try {
			const { data: accept_data } = await terminalCallAPI.accept_call()
			dispatch(setCallRecordId(accept_data?.callRecordId))
			dispatch(setAppealId(accept_data?.appealId))
			router(`/terminals/${id}?session=${accept_data.appealId}`, {
				replace: true,
			})
		} finally {
			closePushNotification()
			resetCallStates()
			audio.pause()
		}
	}

	const handleReject = () => {
		terminalCallAPI.reject_call()
		resetCallStates()
		closePushNotification()
		audio.pause()
	}

	const sendSystemNotification = (data: ICallBody) => {
		if (Notification.permission === 'granted') {
			const systemNotification = new Notification('Новый звонок!', {
				body: `Звонит ${data.terminal.name}\nАдрес: ${data.terminal.address}`,
				icon: '/call-icon.png',
			})

			systemNotification.onclick = () => {
				window.focus()
				router(`/terminals/${data.terminal.id}`)
				systemNotification.close()
			}
		}
	}

	const sendPushNotification = async (data: ICallBody) => {
		if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
			const registration = await navigator.serviceWorker.ready

			registration.showNotification('Новый звонок!', {
				body: `Звонит ${data.terminal.name}\nАдрес: ${data.terminal.address}`,
				icon: '/call-icon.png',
				data: { url: `/terminals/${data.terminal.id}` },
				tag: 'call',
			})
		}
	}

	const closePushNotification = async () => {
		if (navigator.serviceWorker.controller) {
			navigator.serviceWorker.controller.postMessage({
				type: 'CLOSE_CALL_NOTIFICATION',
			})
		}
	}

	return <>{children}</>
}
