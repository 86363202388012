import { Button, notification } from 'antd'

import { devicesAPI } from '@/api'

interface Props {
	terminalId: string
}

export const SettingPrinterKKMModal = ({ terminalId }: Props) => {
	const handleTestPrint = async () => {
		try {
			await devicesAPI.testPrinting({
				terminalId,
				typePrinter: 3,
			})
			notification.success({ message: 'Успешно' })
		} catch (error) {
			console.error(error)
			notification.error({ message: 'Произошла ошибка' })
		}
	}

	return (
		<div className="setting-printer">
			<Button className="setting-printer__btn" onClick={handleTestPrint}>
				Тест печати
			</Button>
		</div>
	)
}
