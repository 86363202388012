import { Button, Input, Select } from 'antd'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { getListTerminals } from '@/store'

import { openErrorNotification, openSuccessNotification } from '@/utils'

import { terminalAPI } from '@/api'

type DayKey =
	| 'monday'
	| 'tuesday'
	| 'wednesday'
	| 'thursday'
	| 'friday'
	| 'saturday'

type FormValues = {
	terminals: Array<string>
} & {
	[key in DayKey]: {
		start: string
		end: string
	}
}

const dayOfWeekMap: Record<DayKey, number> = {
	monday: 1,
	tuesday: 2,
	wednesday: 3,
	thursday: 4,
	friday: 5,
	saturday: 6,
}

const dayLabels: Record<DayKey, string> = {
	monday: 'Пн',
	tuesday: 'Вт',
	wednesday: 'Ср',
	thursday: 'Чт',
	friday: 'Пт',
	saturday: 'Сб',
}

export default function DefaultWorkTimeTerminals() {
	const terminalsList = useAppSelector((state) => state.terminals.terminalsList)
	const currentTerminal = useAppSelector(
		(state) => state.terminals.currentTerminal
	)
	const dispatch = useAppDispatch()

	const {
		control,
		formState: { errors, isValid },
		handleSubmit,
		trigger,
		watch,
		setValue,
	} = useForm<FormValues>({
		mode: 'onChange',
		defaultValues: {
			terminals: [],
			...Object.keys(dayOfWeekMap).reduce(
				(acc, day) => ({
					...acc,
					[day as DayKey]: { start: '', end: '' },
				}),
				{} as Record<DayKey, { start: string; end: string }>
			),
		},
	})

	useEffect(() => {
		dispatch(getListTerminals({}))
	}, [dispatch])

	useEffect(() => {
		const defaultValues = Object.keys(dayOfWeekMap).reduce(
			(acc, day) => ({
				...acc,
				[day as DayKey]: { start: '', end: '' },
			}),
			{} as Record<DayKey, { start: string; end: string }>
		)

		if (currentTerminal?.defaultWorkTimeDays) {
			currentTerminal.defaultWorkTimeDays.forEach((day: any) => {
				const dayKey = Object.entries(dayOfWeekMap).find(
					([, num]) => num === day.day
				)?.[0] as DayKey | undefined

				if (dayKey) {
					defaultValues[dayKey] = {
						start: day.startTime || '',
						end: day.endTime || '',
					}
				}
			})
		}

		Object.entries(defaultValues).forEach(([key, value]) => {
			setValue(key as DayKey, value, { shouldValidate: true })
		})
	}, [currentTerminal, setValue])

	const validateTime = (day: DayKey) => {
		const values = watch(day)
		const bothFilled = !!values.start && !!values.end
		const bothEmpty = !values.start && !values.end

		if (!bothEmpty && !bothFilled) {
			return 'Заполните оба времени'
		}

		if (
			bothFilled &&
			dayjs(values.start, 'HH:mm').isAfter(dayjs(values.end, 'HH:mm'))
		) {
			return 'Время начала должно быть раньше окончания'
		}

		return true
	}

	const onSubmit = async (data: FormValues) => {
		try {
			await trigger()

			if (data.terminals.length === 0) {
				openErrorNotification('Выберите терминалы')
				return
			}

			const { terminals, ...daysData } = data

			const daysOfWeek = (
				Object.entries(daysData) as [DayKey, { start: string; end: string }][]
			)
				.filter(([_, times]) => times.start && times.end)
				.map(([day, times]) => ({
					dayOfWeek: dayOfWeekMap[day],
					startWorkTime: dayjs(times.start, 'HH:mm').format('HH:mm:ss'),
					endWorkTime: dayjs(times.end, 'HH:mm').format('HH:mm:ss'),
				}))

			if (daysOfWeek.length === 0) {
				openErrorNotification('Заполните хотя бы один день полностью')
				return
			}

			await terminalAPI.terminalsDefaultWorkTime({
				terminalIds: data.terminals,
				daysOfWeek,
			})

			openSuccessNotification('Расписание обновлено!')
		} catch (e) {
			console.error('Ошибка сохранения:', e)
			openErrorNotification('Ошибка при сохранении')
		}
	}

	const terminalOptions = terminalsList
		?.filter((item) => item.astipStatus !== 'Disabled')
		?.map((terminal) => ({
			value: terminal.id,
			label: terminal.name,
		}))

	const handleTimeChange =
		(day: DayKey, field: 'start' | 'end') =>
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const value = e.target.value
			setValue(`${day}.${field}` as const, value, { shouldValidate: true })

			// Если изменено одно из полей, валидируем оба
			trigger(day)
		}

	return (
		<div className="work-time">
			<div className="work-time__title _h4">Стандартное расписание работы</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div style={{ marginBottom: 16, width: '100%' }}>
					<Controller
						name="terminals"
						control={control}
						rules={{ required: 'Выберите терминалы' }}
						render={({ field }) => (
							<Select
								mode="multiple"
								options={terminalOptions}
								value={field.value}
								onChange={field.onChange}
								placeholder="Выберите терминалы"
								style={{ width: '100%' }}
							/>
						)}
					/>
					{errors.terminals && (
						<div style={{ color: 'red', marginTop: 4 }}>
							{errors.terminals.message}
						</div>
					)}
				</div>

				<div className="work-time__days-container">
					{Object.entries(dayLabels).map(([day, label]) => {
						const dayKey = day as DayKey
						const error = errors[dayKey]

						return (
							<div
								key={day}
								className={`work-time__day ${error ? 'work-time__day--error' : ''}`}
							>
								<div
									className={`work-time__day-label ${error ? 'work-time__day-label--error' : ''}`}
								>
									{label}
								</div>
								<div className="work-time__inputs">
									<Controller
										name={`${dayKey}.start`}
										control={control}
										rules={{ validate: () => validateTime(dayKey) }}
										render={({ field }) => (
											<Input
												size="small"
												type="time"
												status={error ? 'error' : ''}
												{...field}
												onChange={handleTimeChange(dayKey, 'start')}
											/>
										)}
									/>
									<Controller
										name={`${dayKey}.end`}
										control={control}
										rules={{ validate: () => validateTime(dayKey) }}
										render={({ field }) => (
											<Input
												size="small"
												type="time"
												status={error ? 'error' : ''}
												{...field}
												onChange={handleTimeChange(dayKey, 'end')}
											/>
										)}
									/>
								</div>
								{error?.message && (
									<div style={{ color: 'red', fontSize: 12 }}>
										{error.message}
									</div>
								)}
							</div>
						)
					})}
				</div>

				<Button
					type="primary"
					htmlType="submit"
					className="work-time__submit-button"
					disabled={!isValid}
				>
					Сохранить расписание
				</Button>
			</form>
		</div>
	)
}
