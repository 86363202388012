import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { Button, Input, Select } from 'antd'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import {
	emailField,
	loginField,
	newUserType,
	openErrorNotification,
	openSuccessNotification,
	passField,
	requiredField,
} from '@/utils'

import { usersAPI } from '@/api'

const dataForSelect = [
	{
		value: 'User',
		label: 'Оператор',
	},
	{
		value: 'BillingSync',
		label: 'Пользователь для методов синхронизации',
	},
	{
		value: 'Statistician',
		label: 'Статистик',
	},
]

export const AddNewUser = ({
	onClose,
	fetch,
}: {
	onClose: () => void
	fetch: () => void
}) => {
	const {
		handleSubmit,
		reset,
		control,
		formState: { errors },
	} = useForm<newUserType>()

	const onSubmit: SubmitHandler<newUserType> = async (data) => {
		try {
			await usersAPI.addUser({
				userName: data.userName,
				login: data.login,
				password: data.password,
				role: data.role,
				email: data.email,
			})
			reset()
			fetch()
			onClose()
			openSuccessNotification('Оператор добавлен')
		} catch (e: any) {
			if (e?.response?.data?.message) {
				openErrorNotification(e.response.data.message)
			} else openErrorNotification('Ошибка добавление оператора')
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="inputs-wrap">
				<div className="inputs-wrap__row">
					<div className="input">
						<label className="input__label" htmlFor="userName">
							Имя
						</label>
						<Controller
							name="userName"
							control={control}
							rules={loginField}
							render={({ field }) => (
								<Input placeholder="Имя" id="userName" {...field} />
							)}
						/>
						{errors?.userName && (
							<div className="input__error">
								{errors.userName?.message ? errors.userName?.message : 'Ошибка'}
							</div>
						)}
					</div>
				</div>
				<div className="inputs-wrap__row">
					<div className="input">
						<label className="input__label" htmlFor="email">
							Логин
						</label>
						<Controller
							name="login"
							control={control}
							rules={loginField}
							render={({ field }) => (
								<Input placeholder="Введите логин" id="text" {...field} />
							)}
						/>
						{errors?.login && (
							<div className="input__error">
								{errors.login?.message ? errors.login?.message : 'Ошибка'}
							</div>
						)}
					</div>
				</div>
				<div className="inputs-wrap__row">
					<div className="input">
						<label className="input__label" htmlFor="email">
							Почта
						</label>
						<Controller
							name="email"
							control={control}
							render={({ field }) => (
								<Input
									placeholder="Введите почту"
									id="text"
									type="email"
									{...field}
								/>
							)}
						/>
						{errors?.login && (
							<div className="input__error">
								{errors.login?.message ? errors.login?.message : 'Ошибка'}
							</div>
						)}
					</div>
				</div>
				<div className="inputs-wrap__row">
					<div className="input">
						<label className="input__label" htmlFor="role">
							Роль
						</label>
						<Controller
							name="role"
							control={control}
							rules={requiredField}
							render={({ field }) => (
								<Select
									{...field}
									style={{ width: '100%' }}
									options={dataForSelect}
									id="role"
									placeholder="Выберите роль"
								/>
							)}
						/>
						{errors?.role && (
							<div className="input__error">
								{errors.role?.message ? errors.role?.message : 'Ошибка'}
							</div>
						)}
					</div>
				</div>
				<div className="inputs-wrap__row">
					<div className="input">
						<label className="input__label" htmlFor="password">
							Пароль
						</label>
						<Controller
							name="password"
							control={control}
							rules={passField}
							render={({ field }) => (
								<Input.Password
									{...field}
									placeholder="Пароль"
									id="password"
									iconRender={(visible) =>
										visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
									}
								/>
							)}
						/>
						{errors?.password && (
							<div className="input__error">
								{errors.password?.message ? errors.password?.message : 'Ошибка'}
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="terminals__form-add-bottom">
				<Button htmlType="button" onClick={onClose}>
					Отмена
				</Button>
				<Button htmlType="submit" type="primary">
					Добавить
				</Button>
			</div>
		</form>
	)
}
