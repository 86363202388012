import { Tabs, TabsProps } from 'antd'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
	ApplicationsAccount,
	CallLogList,
	FeedBackList,
	Payments,
	Readings,
	ReceiptsList,
} from '@/components'

const items: TabsProps['items'] = [
	{
		key: '1',
		label: 'Список платежей',
		children: (
			<div className="reports__inner">
				<Payments />
			</div>
		),
	},
	{
		key: '2',
		label: 'Список показаний',
		children: (
			<div className="reports__inner">
				<Readings />
			</div>
		),
	},
	{
		key: '3',
		label: 'Заявки на счета',
		children: (
			<div className="reports__inner">
				<ApplicationsAccount />
			</div>
		),
	},
	{
		key: '4',
		label: 'Журнал вызовов',
		children: (
			<div className="reports__inner">
				<CallLogList />
			</div>
		),
	},
	{
		key: '5',
		label: 'Обратные звонки',
		children: (
			<div className="reports__inner">
				<FeedBackList />
			</div>
		),
	},
	{
		key: '6',
		label: 'Сверка итогов',
		children: (
			<div className="reports__inner">
				<ReceiptsList />
			</div>
		),
	},
]

export function ReportsPage() {
	const [searchParams, setSearchParams] = useSearchParams()
	const initialTab = searchParams.get('tab') || '1'
	const [activeTab, setActiveTab] = useState(initialTab)

	const handleTabChange = (key: string) => {
		setActiveTab(key)
		setSearchParams({ tab: key })
	}

	return (
		<div className="reports">
			<div className="reports__title title">Отчеты</div>
			<Tabs
				activeKey={activeTab}
				onChange={handleTabChange}
				items={items}
				type="card"
				size="large"
				className="reports__tabs"
			/>
		</div>
	)
}
