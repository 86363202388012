import { auth, loadingStatus } from '..'
import Cookies from 'js-cookie'

import {
	LoginData,
	openErrorNotification,
	openSuccessNotification,
} from '@/utils'

import { authAPI } from '@/api'

export const login =
	({ login, password }: LoginData) =>
	async (dispatch: any) => {
		dispatch(loadingStatus(true))

		try {
			const response = await authAPI.login(login, password)
			Cookies.set('accessToken', response.data.token)
			openSuccessNotification('Успешная авторизация')
			dispatch(auth(response.data))
		} catch (e: any) {
			if (e.response.data.email === 'Аккаунт заблокирован') {
				openErrorNotification('Аккаунт заблокирован')
			} else if (e.response) {
				openErrorNotification('Ошибка авторизации. Неверные данные')
			} else {
				openErrorNotification('Ошибка авторизации.')
			}
		}
		dispatch(loadingStatus(false))
	}
