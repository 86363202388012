import { SearchOutlined } from '@ant-design/icons'
import {
	Button,
	DatePicker,
	Input,
	InputRef,
	Pagination,
	Space,
	Table,
	TableColumnType,
	TableColumnsType,
	TableProps,
} from 'antd'
import { FilterDropdownProps } from 'antd/es/table/interface'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'

import { loadingStatus, useAppDispatch } from '@/store'

import { getFeedBackListParams, openErrorNotification } from '@/utils'

import { appealsAPI, terminalAPI } from '@/api'

const { RangePicker } = DatePicker
type OnChange = NonNullable<TableProps<any>['onChange']>
type feedbackType = {
	id: string
	abonentName: string
	abonentPhoneNumber: string
	abonentPerconalAcc: string
	createdAt: string
	terminalId: string
	terminalName: string
	terminalAddress: string
}

export const FeedBackList = () => {
	const searchInput = useRef<InputRef>(null)
	const [dataList, setDataList] = useState<
		undefined | { feedbacks: Array<feedbackType>; totalElementCount: number }
	>(undefined)
	const [terminalsNames, setTerminalsNames] = useState<any>([])
	const dispatch = useAppDispatch()
	const [params, setParams] = useState<getFeedBackListParams>({
		PageNumber: 1,
		PageSize: 10,
	})

	const fetchData = async () => {
		try {
			dispatch(loadingStatus(true))
			const { data } = await appealsAPI.getListFeedBack({ ...params })
			const TerminalNames = await terminalAPI.getNames()
			setTerminalsNames(TerminalNames.data)
			setDataList(data)
		} catch (e) {
			console.error(e)
		} finally {
			dispatch(loadingStatus(false))
		}
	}

	const handleSearch = (
		selectedKeys: string[],
		confirm: FilterDropdownProps['confirm'],
		dataIndex: any
	) => {
		confirm()

		setParams((prev) => ({
			...prev,
			PageNumber: 1,
			SearchStringPersonalAcc: selectedKeys[0],
		}))
	}

	const handleReset = (clearFilters: () => void) => {
		clearFilters()
	}

	const getColumnSearchProps = (dataIndex: any): TableColumnType<any> => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
			close,
		}) => (
			<div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
				<Input
					ref={searchInput}
					placeholder={`Поиск ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) =>
						setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					onPressEnter={() =>
						handleSearch(selectedKeys as string[], confirm, dataIndex)
					}
					style={{ marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() =>
							handleSearch(selectedKeys as string[], confirm, dataIndex)
						}
						size="small"
						style={{ width: 90 }}
					>
						Фильтр
					</Button>
					<Button
						onClick={() => clearFilters && handleReset(clearFilters)}
						size="small"
						style={{ width: 90 }}
					>
						Сбросить
					</Button>

					<Button
						type="link"
						size="small"
						onClick={() => {
							close()
						}}
					>
						Закрыть
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered: boolean) => (
			<SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
		),
		filterDropdownProps: {
			onOpenChange(open) {
				if (open) {
					setTimeout(() => searchInput.current?.select(), 100)
				}
			},
		},
	})

	const columns: TableColumnsType<any> = [
		{
			title: 'Имя абонента',
			dataIndex: 'abonentName',
			key: 'abonentName',
		},
		{
			title: 'Номер телефона',
			dataIndex: 'abonentPhoneNumber',
			key: 'abonentPhoneNumber',
		},
		{
			title: 'Лицевой счет',
			dataIndex: 'abonentPerconalAcc',
			key: 'abonentPerconalAcc',
			...getColumnSearchProps('abonentPerconalAcc'),
		},
		{
			title: 'Дата и время',
			render: (e: any) => (
				<div>{moment(e.createdAt).format('YYYY-MM-DD, HH:mm:ss')}</div>
			),
		},

		{
			title: 'Имя терминала',
			dataIndex: 'terminalName',
			key: 'terminalName',
			filters: terminalsNames?.map((e: any) => ({
				value: e,
				text: e,
			})),
			filterSearch: true,
		},
		{
			title: 'Адрес',
			dataIndex: 'terminalAddress',
			key: 'terminterminalAddressalName',
			width: 200,
		},
	]

	useEffect(() => {
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params])

	const onDateChange = (date: any, dateString: any) => {
		setParams({
			...params,
			StartedAt: dateString[0],
			EndedAt: dateString[1],
			PageNumber: 1,
		})
	}

	const onPageChange = (newPage: number, newPageSize?: number) => {
		setParams((prevParams) => ({
			...prevParams,
			PageNumber: newPage,
			PageSize: newPageSize || prevParams.PageSize,
		}))
	}
	const handleChange: OnChange = (_, filters) => {
		setParams((prev) => ({
			...prev,
			PageNumber: 1,
			TerminalNames: filters.terminalName,
		}))
	}

	const downloadXLSX = async () => {
		try {
			dispatch(loadingStatus(true))
			const { data, headers } = await appealsAPI.downloadListFeedBack(params)

			const contentDisposition = headers['content-disposition']

			let fileName = `отчет обратнык звонки от ${new Date().toLocaleDateString('ru')}.xlsx`

			if (contentDisposition) {
				const fileNameMatch = contentDisposition.match(
					/filename\*?=(["']?)(.*?)\1/i
				)
				if (fileNameMatch && fileNameMatch[2]) {
					fileName = decodeURIComponent(fileNameMatch[2])
				}
			}

			const blob = new Blob([data])
			const link = document.createElement('a')
			link.href = URL.createObjectURL(blob)
			link.download = fileName
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
			URL.revokeObjectURL(link.href)
		} catch (e) {
			console.error(e)
			openErrorNotification('Ошибка открытия документа')
		} finally {
			dispatch(loadingStatus(false))
		}
	}

	return (
		<div className="readings">
			<div className="readings__title">
				<p>Список обратных звонков</p>
				<Button onClick={() => downloadXLSX()}>Скачать</Button>
			</div>
			<div className="readings__filter">
				<div className="readings__filter-text">Фильтр по дате:</div>
				<RangePicker onChange={onDateChange} />
			</div>
			<Table
				dataSource={dataList?.feedbacks}
				columns={columns}
				pagination={false}
				rowKey="id"
				onChange={handleChange}
			/>
			<Pagination
				className="readings__pagination"
				current={params.PageNumber}
				pageSize={params.PageSize}
				total={dataList?.totalElementCount}
				onChange={onPageChange}
			/>
		</div>
	)
}
