import { notification } from 'antd'

export const checkMedia = async () => {
	const devices = await navigator.mediaDevices?.enumerateDevices()
	const hasCamera = devices?.some((device) => device.kind === 'videoinput')
	const hasMicrophone = devices?.some((device) => device.kind === 'audioinput')

	if (!hasCamera) {
		notification.warning({
			message: 'Нет доступа к камере',
			description: 'Пожалуйста, подключите камеру и попробуйте снова.',
		})
		return false
	}

	if (!hasMicrophone) {
		notification.warning({
			message: 'Нет доступа к микрофону',
			description: 'Пожалуйста, подключите микрофон и попробуйте снова.',
		})
		return false
	}

	return true
}
