import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { appelas } from '@/utils'

export type statusItem = {
	id: number
	status: string
}

export type typeItem = {
	id: number
	type: string
}

export type listPayments = {
	id: string
	key: string
	dateTimeCreation: string
	dateTimeLastEditing: string
	statusStringResult: string
	paymentTerminalIsSuccess: boolean
	appealType: string
	receiptIsPrinted: boolean
	receiptIsSentToOFD: boolean
	isSentToBilling: boolean
	moneySum: number
	ofdUuid: string
	receipt: string
	terminal: {
		name: string
	}
	appeal: {
		abonent: {
			personalAcc: string
		}
	}
}

interface InitialStateType {
	statusList: Array<statusItem> | null
	typesList: Array<typeItem> | null
	listAppeals: Array<appelas> | undefined
	currentAppeals: appelas | null
	listPayments: Array<listPayments> | undefined
	listReadings: Array<any> | undefined
	applicationsAccount: any | undefined
}

const initialState: InitialStateType = {
	statusList: null,
	typesList: null,
	listAppeals: undefined,
	currentAppeals: null,
	listPayments: undefined,
	listReadings: undefined,
	applicationsAccount: undefined,
}

export const appealsReducer = createSlice({
	name: 'appeals',
	initialState,
	reducers: {
		setStatusList: (state, action: PayloadAction<Array<statusItem>>) => {
			state.statusList = action.payload
		},
		setTypesList: (state, action: PayloadAction<Array<typeItem>>) => {
			state.typesList = action.payload
		},
		setAppealsList: (state, action: PayloadAction<Array<appelas>>) => {
			state.listAppeals = action.payload
		},
		setCurrentAppeals: (state, action: PayloadAction<appelas | null>) => {
			state.currentAppeals = action.payload
		},
		setListPayments: (state, action: PayloadAction<any | undefined>) => {
			state.listPayments = action.payload
		},
		setListReadings: (state, action: PayloadAction<any | undefined>) => {
			state.listReadings = action.payload
		},
		setApplicationsAccount: (state, action: PayloadAction<any | undefined>) => {
			state.applicationsAccount = action.payload
		},
	},
})

export const {
	setStatusList,
	setTypesList,
	setAppealsList,
	setCurrentAppeals,
	setListPayments,
	setListReadings,
	setApplicationsAccount,
} = appealsReducer.actions

export default appealsReducer.reducer
