import { SearchOutlined } from '@ant-design/icons'
import {
	Button,
	DatePicker,
	Input,
	InputRef,
	Modal,
	Pagination,
	Space,
	Table,
	TableColumnType,
	TableColumnsType,
	TableProps,
} from 'antd'
import { FilterDropdownProps } from 'antd/es/table/interface'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { loadingStatus } from 'store/app'
import { listPayments } from 'store/appeals'
import { useAppDispatch } from 'store/hooks'

import {
	getPaymentsParams,
	kopecksToRubles,
	openErrorNotification,
} from '@/utils'

import { appealsAPI, terminalAPI } from '@/api'

type OnChange = NonNullable<TableProps<any>['onChange']>
const { RangePicker } = DatePicker

const parseReceiptData = (data: any) => {
	try {
		let parsedData
		try {
			parsedData = JSON.parse(data)
		} catch (error) {
			if (typeof data === 'string') {
				let escapedString = data.replaceAll(/"/g, '\\"')

				let validJsonString = escapedString
					.replaceAll(/([{,]\s*)'([^']+)'\s*:/g, '$1"$2":')
					.replaceAll(/:\s*'([^']*)'\s*([,}])/g, ': "$1"$2')

				validJsonString = validJsonString
					.replaceAll(/True/g, 'true')
					.replaceAll(/False/g, 'false')
					.replaceAll(/None/g, 'null')

				parsedData = JSON.parse(validJsonString)
			} else {
				parsedData = data
			}
		}

		return (
			<pre>
				<code>{JSON.stringify(parsedData, null, 2)}</code>
			</pre>
		)
	} catch (error) {
		const lines = data?.split('\n')
		return lines.map((line: string, index: number) => {
			const match = line.match(/([A-Za-zА-Яа-я\s]+):\s*(.*)/)
			if (match) {
				const [, key, value] = match
				return (
					<div key={index} className="receipt__item">
						<p>
							<strong>{key.trim()}:</strong>
						</p>
						<span>{value.trim()}</span>
					</div>
				)
			}
			return null
		})
	}
}

interface propsModal extends Partial<listPayments> {
	open: boolean
}

type paymentListType = {}

export const Payments = () => {
	const searchInput = useRef<InputRef>(null)
	const [terminalsNames, setTerminalsNames] = useState<any>([])
	const [dataList, setDataList] = useState<
		undefined | { payments: Array<paymentListType>; totalElementCount: number }
	>(undefined)
	const [params, setParams] = useState<getPaymentsParams>({
		PageNumber: 1,
		PageSize: 10,
	})
	const dispatch = useAppDispatch()

	const [modal, setModal] = useState<propsModal>({
		open: false,
	})

	const fetchData = async () => {
		dispatch(loadingStatus(true))
		try {
			const { data } = await appealsAPI.getPaymentsList({ ...params })
			const TerminalNames = await terminalAPI.getNames()
			setTerminalsNames(TerminalNames.data)
			setDataList(data)
		} catch (e) {
			console.error(e)
		} finally {
			dispatch(loadingStatus(false))
		}
	}

	const handleSearch = (
		selectedKeys: string[],
		confirm: FilterDropdownProps['confirm'],
		dataIndex: any
	) => {
		confirm()

		setParams((prev) => ({
			...prev,
			PageNumber: 1,
			SearchStringPersonalAcc: selectedKeys[0],
		}))
	}

	const handleReset = (clearFilters: () => void) => {
		clearFilters()
	}

	const getColumnSearchProps = (dataIndex: any): TableColumnType<any> => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
			close,
		}) => (
			<div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
				<Input
					ref={searchInput}
					placeholder={`Поиск ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) =>
						setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					onPressEnter={() =>
						handleSearch(selectedKeys as string[], confirm, dataIndex)
					}
					style={{ marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() =>
							handleSearch(selectedKeys as string[], confirm, dataIndex)
						}
						size="small"
						style={{ width: 90 }}
					>
						Фильтр
					</Button>
					<Button
						onClick={() => clearFilters && handleReset(clearFilters)}
						size="small"
						style={{ width: 90 }}
					>
						Сбросить
					</Button>

					<Button
						type="link"
						size="small"
						onClick={() => {
							close()
						}}
					>
						Закрыть
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered: boolean) => (
			<SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
		),
		filterDropdownProps: {
			onOpenChange(open) {
				if (open) {
					setTimeout(() => searchInput.current?.select(), 100)
				}
			},
		},
	})

	const columns: TableColumnsType<any> = [
		{
			title: 'Лицевой счет',
			dataIndex: 'appeal',
			key: 'abonentPerconalAcc',
			render: (e: any) => <span>{e?.abonent?.personalAcc}</span>,
			...getColumnSearchProps('abonentPerconalAcc'),
		},
		{
			title: 'Имя терминала',
			dataIndex: 'appeal',
			key: 'appeal',
			render: (e: any) => <span>{e?.terminal?.name}</span>,
			filters: terminalsNames?.map((e: any) => ({
				value: e,
				text: e,
			})),
			filterSearch: true,
		},
		{
			title: 'Время',
			render: (e: any) => (
				<div>{moment(e.dateTimeCreation).format('YYYY-MM-DD, HH:mm:ss')}</div>
			),
		},
		{
			title: 'Сумма',
			dataIndex: 'moneySum',
			key: 'moneySum',
			render: (e: any) => <span>{kopecksToRubles(e)}</span>,
		},
		{
			title: 'Способ оплаты',
			dataIndex: 'paymentType',
			key: 'paymentType',
			filterMultiple: false,
			filters: [
				{
					value: 0,
					text: 'Терминал',
				},
				{
					value: 1,
					text: 'СБП',
				},
			],
		},

		{
			title: 'В реестре',
			dataIndex: 'isSentToBilling',
			render: (e: any) => <span>{e ? 'Да' : 'Нет'}</span>,
		},
		{
			title: 'Ссылка на чек',
			key: 'receipt',
			render: (e: any) => (
				<button
					className="_highlight"
					onClick={() => {
						setModal({
							receipt: e.receipt,
							open: true,
							id: e.id,
							receiptIsSentToOFD: e.receiptIsSentToOFD,
						})
					}}
				>
					{e.receipt ? e.receipt !== 'SBP qrcode generation' && 'Чек' : ''}
				</button>
			),
		},
		{
			title: 'Результат платежа',
			dataIndex: 'paymentTerminalIsSuccess',
			filterMultiple: false,
			render: (e: any) => <span>{e ? 'Успешно' : 'Ошибка'}</span>,
			filters: [
				{
					value: false,
					text: 'Ошибка',
				},
				{
					value: true,
					text: 'Успешно',
				},
			],
		},
		{
			title: 'Распечатан чек',
			dataIndex: 'receiptIsPrinted',
			render: (e: any) => <span>{e ? 'Да' : 'Нет'}</span>,
		},
		{
			title: 'Отправлен в OFD',
			dataIndex: 'receiptIsSentToOFD',
			render: (e: any) => <span>{e ? 'Да' : 'Нет'}</span>,
		},
		{
			title: 'ID OFD',
			dataIndex: 'ofdUuid',
			render: (e: any) => <span>{e ? e : '-'}</span>,
		},
	]

	const onDateChange = (date: any, dateString: any) => {
		setParams({
			...params,
			StartedAt: dateString[0],
			EndedAt: dateString[1],
			PageNumber: 1,
		})
	}

	const onPageChange = (newPage: number, newPageSize?: number) => {
		setParams((prevParams) => ({
			...prevParams,
			PageNumber: newPage,
			PageSize: newPageSize || prevParams.PageSize,
		}))
	}
	const handleChange: OnChange = (_, filters) => {
		setParams((prev) => ({
			...prev,
			PageNumber: 1,
			TerminalNames: filters.appeal,
			SearchStringPersonalAcc: filters.abonentPerconalAcc
				? filters.abonentPerconalAcc[0]
				: undefined,
			IsSuccess: filters.paymentTerminalIsSuccess
				? filters.paymentTerminalIsSuccess[0]
				: undefined,
			PaymentType: filters.paymentType ? filters.paymentType[0] : undefined,
		}))
	}

	useEffect(() => {
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params])

	const getDownloadCheck = async (id: string) => {
		try {
			dispatch(loadingStatus(true))
			const { data } = await appealsAPI.getPaymentReceipt({ PaymentId: id })
			const blob = new Blob([data])
			const link = document.createElement('a')
			const blobUrl = window.URL.createObjectURL(blob)
			link.href = blobUrl
			link.download = 'check.pdf'
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		} catch (e) {
			console.error(e)
			openErrorNotification('Ошибка скачивания чека')
		} finally {
			dispatch(loadingStatus(false))
		}
	}

	const downloadXLSX = async () => {
		try {
			dispatch(loadingStatus(true))
			const { data, headers } = await appealsAPI.downloadPaymentsList(params)

			const contentDisposition = headers['content-disposition']

			let fileName = `отчет список платежей от ${new Date().toLocaleDateString('ru')}.xlsx`

			if (contentDisposition) {
				const fileNameMatch = contentDisposition.match(
					/filename\*?=(["']?)(.*?)\1/i
				)
				if (fileNameMatch && fileNameMatch[2]) {
					fileName = decodeURIComponent(fileNameMatch[2])
				}
			}

			const blob = new Blob([data])
			const link = document.createElement('a')
			link.href = URL.createObjectURL(blob)
			link.download = fileName
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
			URL.revokeObjectURL(link.href)
		} catch (e) {
			console.error(e)
			openErrorNotification('Ошибка открытия документа')
		} finally {
			dispatch(loadingStatus(false))
		}
	}

	return (
		<>
			<div className="payments">
				<div className="readings__title">
					<p>Список платежей</p>
					<Button onClick={() => downloadXLSX()}>Скачать</Button>
				</div>
				<div className="jornal__filter">
					<div className="jornal__filter-text">Фильтр по дате:</div>
					<RangePicker onChange={onDateChange} />
				</div>
				<Table
					dataSource={dataList?.payments}
					columns={columns}
					rowKey="id"
					pagination={false}
					onChange={handleChange}
				/>
				<Pagination
					className="readings__pagination"
					current={params.PageNumber}
					pageSize={params.PageSize}
					total={dataList?.totalElementCount}
					onChange={onPageChange}
				/>
			</div>
			<Modal
				open={modal.open}
				title="Чек"
				centered
				className="terminal__modal"
				onOk={() => setModal({ open: false })}
				onCancel={() => setModal({ open: false })}
				footer={null}
				styles={{
					body: { maxHeight: '75vh', overflowY: 'auto' },
				}}
			>
				<div className="modal-content">
					{modal.receipt && parseReceiptData(modal.receipt)}
					{modal.receiptIsSentToOFD && (
						<Button
							type="default"
							style={{ margin: 'auto', display: 'block' }}
							onClick={() => getDownloadCheck(modal.id!)}
						>
							Скачать
						</Button>
					)}
				</div>
			</Modal>
		</>
	)
}
