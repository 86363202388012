import { loadingStatus, setEventType, setImportanceType, setJournal } from '..'

import { openErrorNotification } from '@/utils'

import { journalsAPI } from '@/api'

export const getJournals =
	({
		id,
		DateTimeStart,
		DateTimeEnd,
		EventType,
		Importance,
		PageNumber,
		PageSize,
	}: {
		id: string
		DateTimeStart?: string
		DateTimeEnd?: string
		EventType?: string
		Importance?: string
		PageNumber?: number
		PageSize?: number
	}) =>
	async (dispatch: any) => {
		dispatch(loadingStatus(true))

		try {
			const { data } = await journalsAPI.getJournals({
				id,
				DateTimeStart,
				DateTimeEnd,
				EventType: EventType,
				Importance: Importance,
				PageSize,
				PageNumber,
			})
			if (data) {
				dispatch(setJournal(data))
			}
		} catch (e: any) {
			openErrorNotification('Ошибка получения информации о журнале событий')
			console.error(e)
		}
		dispatch(loadingStatus(false))
	}

export const getJournalsTypes = () => async (dispatch: any) => {
	dispatch(loadingStatus(true))

	try {
		const importance = await journalsAPI.getImportance()
		const event = await journalsAPI.getEventType()

		dispatch(setImportanceType(importance.data))
		dispatch(setEventType(event.data))
	} catch (e: any) {
		openErrorNotification('Ошибка получения типов')
		console.error(e)
	}
	dispatch(loadingStatus(false))
}
