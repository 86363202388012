import { UploadOutlined } from '@ant-design/icons'
import { Button, Input, Modal, Upload, message, notification } from 'antd'
import { memo, useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Equipment, FileUploadModal } from '@/components'

import { loadingStatus, useAppDispatch, useAppSelector } from '@/store'

import { nameDevice } from '@/utils'

import { backgroundVideosAPI, terminalAPI } from '@/api'

import TerminalMaintenance from './TerminalMaintenance'
import { TerminalSettingsMain } from './TerminalSettingsMain'

export const TerminalSettings = memo(({ id }: { id: string }) => {
	const terminalInfo = useAppSelector(
		(state) => state.terminals.currentTerminal
	)
	const terminalConfig = useAppSelector(
		(state) => state.terminals.currentTerminalConfig
	)

	const DeviceSettings = memo(() => (
		<div className="terminalSettings__config-device">
			<div className="terminalSettings__title _h4">Настройка устройств</div>
			<div className="terminalSettings__config-device-item">
				{terminalInfo?.devices?.map((device) => (
					<Equipment
						TerminalId={id}
						key={device.id}
						title={nameDevice[device.type]?.name}
						status={device.status}
						icon={nameDevice[device.type]?.icon}
						setting
					/>
				))}
			</div>
		</div>
	))

	const downloadFile = async () => {
		try {
			const response = await terminalAPI.getConfig({ TerminalId: id })

			const contentDisposition = response.headers['Content-Disposition']

			let fileName = 'config.json'

			if (contentDisposition) {
				const fileNameMatch = contentDisposition.match(
					/filename\*?=(?:UTF-8''([\w%\-.]+)|"([^"]+)")/
				)
				fileName = fileNameMatch
					? decodeURIComponent(fileNameMatch[1] || fileNameMatch[2])
					: fileName
			}

			const blob = new Blob([response.data])
			const url = window.URL.createObjectURL(blob)

			const link = document.createElement('a')
			link.href = url
			link.download = fileName
			document.body.appendChild(link)
			link.click()

			document.body.removeChild(link)
			window.URL.revokeObjectURL(url)
		} catch (error) {
			console.error('Ошибка при скачивании файла:', error)
			notification.error({ message: 'Ошибка при скачивании файла' })
		}
	}

	return (
		<div className="terminalSettings">
			{terminalInfo && terminalConfig && (
				<TerminalSettingsMain terminalInfo={terminalInfo} />
			)}
			<div className="terminalSettings__content">
				<div className="terminalSettings__item">
					<div className="terminalSettings__config">
						<div className="terminalSettings__title _h4">
							Конфигурация подсказок ТИП
						</div>
						<FileUploadConfig terminalId={id} />
						<Button
							className="terminalSettings__btn-down"
							type="primary"
							onClick={downloadFile}
						>
							Скачать файл конфигурации
						</Button>
					</div>
					<div className="terminalSettings__reklama">
						<FileUploadRRSection
							title={`Настройка информационного раздела "Личный кабинет"`}
							type={1}
							terminalId={id}
						/>
						<FileUploadRRSection
							title={`Настройка информационного раздела "Догазификация"`}
							type={2}
							terminalId={id}
						/>
						<FileUploadRRSection
							title={`Настройка информационного раздела "Чат бот"`}
							type={3}
							terminalId={id}
						/>
						<FileUploadSection title="Загрузить заставку" terminalId={id} />
					</div>
				</div>
				<div className="terminalSettings__item">
					<DeviceSettings />
					{terminalInfo && (
						<TerminalMaintenance terminalId={id} terminalInfo={terminalInfo} />
					)}
				</div>
			</div>
		</div>
	)
})

interface FileUploadRRSectionProps {
	title: string
	type: 1 | 2 | 3
	terminalId: string
}

const FileUploadRRSection = memo<FileUploadRRSectionProps>(
	({ title, type, terminalId }) => {
		const [openFile, setOpenFile] = useState(false)
		const [modalQR, setModalQR] = useState<{
			open: boolean
			type?: number
		}>({ open: false })
		const [list, setList] = useState<any[]>([])
		const [active, setActive] = useState<any>(null)

		const closeModalFile = useCallback(() => {
			setOpenFile(false)
		}, [])

		const closeModalQR = useCallback(() => {
			setModalQR({ open: false })
			reset({ qrCode: '' })
		}, [])

		const getList = async () => {
			try {
				const { data } = await backgroundVideosAPI.getAdvertisement({ type })
				setList(data)
				setActive(
					data.find((item: any) =>
						item.astiPs.some((astip: any) => astip.id === terminalId)
					)
				)
			} catch (e: any) {
				message.error(e.message)
			}
		}

		useEffect(() => {
			getList()
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		const { control, handleSubmit, reset } = useForm({
			mode: 'onSubmit',
		})

		const onSubmit = async (data: any) => {
			try {
				await terminalAPI.setQr({
					terminalId,
					qrCode: data.qrCode,
					type: modalQR.type!,
				})
				if (modalQR.type) {
					await terminalAPI.setQr({
						terminalId,
						qrCode: data.qrCodeText ? data.qrCodeText : '',
						type: +modalQR.type + 6,
					})
				}

				notification.success({ message: 'QR успешно добавлен' })
				closeModalQR()
			} catch (error: any) {
				console.error(error)
				notification.error({ message: error.response.data.message })
			}
		}

		const getTypeQR1: { [key: number]: number } = {
			1: 1,
			2: 3,
			3: 5,
		}

		const getTypeQR2: { [key: number]: number } = {
			1: 2,
			2: 4,
			3: 6,
		}

		return (
			<>
				<h2 className="terminalSettings__title">{title}</h2>
				<div className="terminalSettings__wrap">
					<div className="terminalSettings__left terminalSettings__left-inner">
						<p className="terminalSettings__label">Рекламный ролик</p>
						<Button onClick={() => setOpenFile(true)}>Выбрать</Button>

						{active ? (
							<p className="terminalSettings__chosen">
								Выбрано: <b>{active.name}</b>
							</p>
						) : (
							<p className="terminalSettings__chosen">Рекламный ролик выбран</p>
						)}
					</div>
					<div className="terminalSettings__right">
						<div className="terminalSettings__qr-block">
							<p className="terminalSettings__label">QR код 1</p>
							<Button
								onClick={() =>
									setModalQR({ open: true, type: getTypeQR1[type] })
								}
							>
								Установить
							</Button>
						</div>
						<div className="terminalSettings__qr-block">
							<p className="terminalSettings__label">QR код 2</p>
							<Button
								onClick={() =>
									setModalQR({ open: true, type: getTypeQR2[type] })
								}
							>
								Установить
							</Button>
						</div>
					</div>
				</div>
				<Modal
					title={`Загрузить рекламный ролик в раздел ${type}`}
					open={openFile}
					onCancel={closeModalFile}
					footer={null}
				>
					<FileUploadModal
						list={list}
						terminalId={terminalId}
						getList={getList}
						type="rr"
						AdType={type}
					/>
				</Modal>
				<Modal
					title={`Создать QR в раздел ${type}`}
					open={modalQR.open}
					onCancel={closeModalQR}
					footer={null}
					className="qr-modal"
				>
					<form onSubmit={handleSubmit(onSubmit)} className="qr-modal-form">
						<div className="qr-modal-input">
							<Controller
								control={control}
								name="qrCode"
								render={({ field }) => (
									<Input type="text" {...field} placeholder="Введите ссылку" />
								)}
							/>{' '}
						</div>
						<div className="qr-modal-input">
							<Controller
								control={control}
								name="qrCodeText"
								render={({ field }) => (
									<Input type="text" {...field} placeholder="Введите текст" />
								)}
							/>
						</div>
						<Button className="qr-modal-form-submit" htmlType="submit">
							Создать
						</Button>
					</form>
				</Modal>
			</>
		)
	}
)

const FileUploadSection = memo(
	({ title, terminalId }: { title: string; terminalId: string }) => {
		const [open, setOpen] = useState(false)
		const [list, setList] = useState<any[]>([])
		const [active, setActive] = useState<any>(null)

		const closeModal = useCallback(() => {
			setOpen(false)
		}, [])

		const getList = async (): Promise<void> => {
			try {
				const { data } = await backgroundVideosAPI.getScreenSavers()
				setList(data)
				setActive(
					data.find((item) =>
						item.astiPs.some((astip) => astip.id === terminalId)
					)
				)
			} catch (e: any) {
				message.error(e.message)
			}
		}

		useEffect(() => {
			getList()
		}, [])

		return (
			<>
				<div className="terminalSettings__config-download">
					<div className="terminalSettings__title _h4 _2">{title}</div>
					<div
						className="terminalSettings__inner"
						style={{ paddingLeft: '16px' }}
					>
						<Button onClick={() => setOpen(true)}>Выбрать</Button>
					</div>
					<div
						className="terminalSettings__list"
						style={{ paddingLeft: '16px' }}
					>
						{active ? (
							<div className="terminalSettings__list-active" key={active.id}>
								<p>
									Выбрано: <b>{active.name}</b>
								</p>
							</div>
						) : (
							<p>Заставка выбрана</p>
						)}
					</div>
				</div>
				<Modal
					title="Загрузить заставку"
					open={open}
					onOk={closeModal}
					onCancel={closeModal}
					footer={null}
				>
					<FileUploadModal
						list={list}
						terminalId={terminalId}
						getList={getList}
						type="screensaver"
					/>
				</Modal>
			</>
		)
	}
)

const FileUploadConfig = memo(({ terminalId }: { terminalId: string }) => {
	const dispatch = useAppDispatch()
	const [open, setOpen] = useState(false)

	const closeModal = useCallback(() => {
		setOpen(false)
	}, [])

	return (
		<>
			<Button onClick={() => setOpen(true)}>Загрузить конфигурацию</Button>
			<Modal
				title="Загрузить конфигурацию"
				open={open}
				onOk={closeModal}
				onCancel={closeModal}
				footer={null}
			>
				<Upload
					beforeUpload={async (file) => {
						dispatch(loadingStatus(true))
						try {
							await terminalAPI.setConfig({
								TerminalId: terminalId,
								Config: file,
							})
							notification.success({
								message: 'Успешно',
								description: 'Конфигурация успешно загружена',
							})
						} catch (error: any) {
							notification.error({ message: error.message })
						} finally {
							dispatch(loadingStatus(false))
						}
						return false
					}}
					showUploadList={false}
				>
					<Button icon={<UploadOutlined />}>
						Загрузить новую конфигурацию
					</Button>
				</Upload>
			</Modal>
		</>
	)
})
