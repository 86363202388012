import {
	DatePicker,
	Modal,
	Pagination,
	Spin,
	Table,
	TableColumnsType,
	TableProps,
} from 'antd'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { truncateWithEllipsis } from 'utils/truncateWithEllipsis'

import {
	getJournals,
	getJournalsTypes,
	useAppDispatch,
	useAppSelector,
} from '@/store'

const { RangePicker } = DatePicker

type OnChange = NonNullable<TableProps<any>['onChange']>
type Filters = Parameters<OnChange>[1]

interface DataType {
	key: React.Key
	id: string
	dateTimeCreation: string
	importance: string
	eventType: string
	message: string
}

export default function TerminalJurnal({ id }: { id: string }) {
	const { journalList, eventType, importanceType } = useAppSelector(
		(state) => state.journals
	)
	const [open, setOpen] = useState(false)
	const [selectedMessage, setSelectedMessage] = useState<string | null>(null)
	const [loading, setLoading] = useState(false)
	const [params, setParams] = useState({
		id,
		PageNumber: 1,
		PageSize: 10,
		DateTimeStart: '',
		DateTimeEnd: '',
		EventType: '',
		Importance: '',
	})

	const dispatch = useAppDispatch()

	const fetchData = useCallback(async () => {
		setLoading(true)
		try {
			await dispatch(getJournals(params))
		} finally {
			setLoading(false)
		}
	}, [params, dispatch])

	useEffect(() => {
		fetchData()
		dispatch(getJournalsTypes())
	}, [fetchData])

	const columns: TableColumnsType<DataType> = useMemo(
		() => [
			{
				title: 'Дата',
				dataIndex: 'dateTimeCreation',
				width: 250,
				render: (obj) => (
					<div>{moment(obj).format('YYYY-MM-DD, HH:mm:ss')}</div>
				),
			},
			{
				title: 'Важность',
				dataIndex: 'importance',
				filterMultiple: false,
				width: 250,

				filters: importanceType?.map((e: any) => ({
					text: e.type,
					value: e.type,
				})),
			},
			{
				title: 'Тип события',
				dataIndex: 'eventType',
				filterMultiple: false,
				width: 250,

				filters: eventType?.map((e: any) => ({
					text: e.type,
					value: e.type,
				})),
			},
			{
				title: 'Описание',
				dataIndex: 'message',
				render: (text: string) => {
					const isTruncated = text.length > 150
					const truncated = truncateWithEllipsis(text, 150)

					return isTruncated ? (
						<button
							className="_highlight"
							onClick={() => {
								setSelectedMessage(text)
								setOpen(true)
							}}
						>
							{truncated}
						</button>
					) : (
						<span>{text}</span>
					)
				},
			},
		],
		[eventType, importanceType]
	)

	const handleChange: OnChange = (_, filters) => {
		setParams((prev) => ({
			...prev,
			PageNumber: 1,
			EventType: filters.eventType?.[0]?.toString() || '',
			Importance: filters.importance?.[0]?.toString() || '',
		}))
	}

	const onDateChange = (_: any, dateString: [string, string]) => {
		setParams((prev) => ({
			...prev,
			DateTimeStart: dateString[0],
			DateTimeEnd: dateString[1],
			PageNumber: 1,
		}))
	}

	const onPageChange = (newPage: number, newPageSize?: number) => {
		setParams((prev) => ({
			...prev,
			PageNumber: newPage,
			PageSize: newPageSize || prev.PageSize,
		}))
	}

	const closeModal = useCallback(() => setOpen(false), [])

	return (
		<>
			<div className="jornal">
				<div className="jornal__title _h4">Журнал событий</div>
				<div className="jornal__filter">
					<div className="jornal__filter-text">Фильтр по дате:</div>
					<RangePicker onChange={onDateChange} />
				</div>

				<Spin spinning={loading} tip="Загрузка...">
					<Table
						columns={columns}
						dataSource={journalList?.journal?.map((item) => ({
							...item,
							key: item.id,
						}))}
						onChange={handleChange}
						pagination={false}
					/>

					<Pagination
						className="terminal__pagination"
						current={params.PageNumber}
						pageSize={params.PageSize}
						total={journalList?.totalElementCount}
						onChange={onPageChange}
						showSizeChanger={false}
					/>
				</Spin>
			</div>

			<Modal
				title="Описание события"
				open={open}
				onCancel={closeModal}
				footer={null}
				destroyOnClose
			>
				<div style={{ maxHeight: '60vh', overflow: 'auto' }}>
					{selectedMessage}
				</div>
			</Modal>
		</>
	)
}
