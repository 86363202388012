import { PlusOutlined } from '@ant-design/icons'
import {
	Button,
	DatePicker,
	Modal,
	Pagination,
	Spin,
	Table,
	TableProps,
} from 'antd'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'

import { appelas } from '@/utils'

import { appealsAPI } from '@/api'

import { AddAppealsModal, AppealsModal } from '../modal'

const { RangePicker } = DatePicker

export function TerminalAppeals({ id }: { id: string }) {
	const [modalState, setModalState] = useState<{
		appealsAdd: boolean
		appeals: boolean
		appealsId: string | null
	}>({
		appealsAdd: false,
		appeals: false,
		appealsId: null,
	})
	const [listAppeals, setListAppeals] = useState([])
	const [page, setPage] = useState<number>(1)
	const [pageSize, setPageSize] = useState<number>(10)
	const [totalElements, setTotalElements] = useState<number>(1)
	const [dateFilter, setDateFilter] = useState<any>(null)
	const [loading, setLoading] = useState<boolean>(false)

	const columns: TableProps<appelas>['columns'] = useMemo(
		() => [
			{
				title: 'Дата',
				defaultSortOrder: 'descend',
				render: (obj) => (
					<button
						className="_highlight"
						onClick={() => {
							setModalState({
								...modalState,
								appealsId: obj.id,
								appeals: true,
							})
						}}
					>
						{moment(obj.dateTimeCreation).format('YYYY-MM-DD, HH:mm:ss')}
					</button>
				),
				sorter: (a, b) =>
					Date.parse(a.dateTimeCreation) - Date.parse(b.dateTimeCreation),
			},
			{
				title: 'Тип обращения',
				dataIndex: 'appealType',
				key: 'appealType',
			},
			{
				title: 'Статус',
				dataIndex: 'status',
				key: 'status',
			},
			{
				title: 'Оператор',
				dataIndex: 'user',
				render: (text) => (
					<span>{text?.userName ? text.userName : 'Нет пользователя'}</span>
				),
			},
		],
		[modalState]
	)

	const fetch = async (newPage?: number) => {
		setLoading(true)
		setModalState((prevState) => ({ ...prevState, appealsAdd: false }))
		try {
			const { data } = await appealsAPI.getList({
				AstipId: id,
				PageSize: pageSize,
				PageNumber: newPage ? newPage : page,
				CreatedStart: dateFilter?.DateTimeStart || null,
				CreatedEnd: dateFilter?.DateTimeEnd || null,
			})
			setTotalElements(data.totalElementCount)
			setListAppeals(data.appeals)
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		fetch()
	}, [])

	useEffect(() => {
		fetch()
	}, [dateFilter])

	const toggleModal = (modalName: 'appealsAdd' | 'appeals', state: boolean) => {
		setModalState((prevState) => ({ ...prevState, [modalName]: state }))
	}

	const onDateChange = (date: any, dateString: any) => {
		setPage(1)
		setDateFilter({
			DateTimeStart: dateString[0],
			DateTimeEnd: dateString[1],
		})
	}

	const onPageChange = (newPage: number, newPageSize?: number) => {
		setPage(newPage)
		fetch(newPage)
		if (newPageSize) {
			setPageSize(newPageSize)
		}
	}

	return (
		<>
			<div className="terminal__appeals-wrap">
				<div className="terminal__appeals-title _h4">Обращения</div>
				<div className="jornal__filter">
					<div className="jornal__filter-text">Фильтр по дате:</div>
					<RangePicker onChange={onDateChange} />
				</div>
				<Button
					className="terminal__appeals-btn"
					type="primary"
					icon={<PlusOutlined />}
					onClick={() => toggleModal('appealsAdd', true)}
				>
					Добавить обращение
				</Button>
				<div className="terminal__table">
					<Spin spinning={loading} tip="Загрузка...">
						<Table
							pagination={false}
							dataSource={listAppeals.map((item: any) => ({
								...item,
								key: item.id,
							}))}
							columns={columns}
						/>
						<Pagination
							className="terminal__pagination"
							current={page}
							pageSize={pageSize}
							align="end"
							total={totalElements}
							onChange={onPageChange}
							showSizeChanger={false}
							pageSizeOptions={['10', '20', '50']}
						/>
					</Spin>
				</div>
			</div>
			<Modal
				open={modalState.appealsAdd}
				title="Добавление обращения"
				centered
				width={'100%'}
				style={{ maxWidth: '1000px', padding: '20px' }}
				className="terminal__modal"
				onOk={() => toggleModal('appealsAdd', false)}
				onCancel={() => toggleModal('appealsAdd', false)}
				footer={null}
				destroyOnClose
			>
				<AddAppealsModal terminalId={id} fetch={fetch} />
			</Modal>
			<Modal
				open={modalState.appeals}
				title="Информация по обращению"
				centered
				width={'100%'}
				style={{ maxWidth: '1000px', padding: '20px' }}
				className="terminal__modal"
				onOk={() => {
					toggleModal('appeals', false)
					fetch()
				}}
				onCancel={() => {
					toggleModal('appeals', false)
					fetch()
				}}
				footer={null}
				onClose={() => {
					fetch()
				}}
			>
				<AppealsModal
					terminalId={id}
					fetch={fetch}
					appealsId={modalState.appealsId}
				/>
			</Modal>
		</>
	)
}
