import { Button, DatePicker, Input, Pagination, Table } from 'antd'
import debounce from 'lodash.debounce'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { loadingStatus } from 'store/app'
import { getAccountRequestsReport } from 'store/appeals'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { getAccountRequestsReportType, openErrorNotification } from '@/utils'

import { appealsAPI } from '@/api'

const { RangePicker } = DatePicker
const { Search } = Input

export const ApplicationsAccount = () => {
	const { applicationsAccount } = useAppSelector((state) => state.appeals)
	const dispatch = useAppDispatch()
	const [params, setParams] = useState<getAccountRequestsReportType>({
		PageNumber: 1,
		PageSize: 10,
	})

	const fetchData = async () => {
		dispatch(
			getAccountRequestsReport({
				...params,
			})
		)
	}

	const columns = [
		{
			title: 'Лицевой счет',
			dataIndex: 'abonent',
			key: 'abonent',
			render: (e: any) => <span>{e?.personalAcc}</span>,
		},
		{
			title: 'Номер телефона',
			dataIndex: 'abonent',
			key: 'phone',
			render: (e: any) => <span>{e?.phoneNumber}</span>,
		},
		{
			title: 'Дата и время',
			render: (e: any) => (
				<div>
					{moment(e.dateTimeLastEditing).format('YYYY-MM-DD, HH:mm:ss')}
				</div>
			),
		},
		{
			title: 'В реестре',
			dataIndex: 'isSyncToBilling',
			key: 'isSyncToBilling',
			filters: [
				{ text: 'Да', value: true },
				{ text: 'Нет', value: false },
			],
			render: (e: boolean) => <span>{e ? 'Да' : 'Нет'}</span>,
			onFilter: (value: any, record: any) => record.isSyncToBilling === value,
		},
		{
			title: 'Комментарии',
			dataIndex: 'comment',
			key: 'comment',
		},
	]

	useEffect(() => {
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params])

	const debouncedSearch = useCallback(
		(value: string) => {
			debounce((searchValue: string) => {
				setParams((prevParams) => ({
					...prevParams,
					SearchString: searchValue,
					PageNumber: 1,
				}))
			}, 500)(value)
		},
		[setParams]
	)

	const onSearch = (value: string) => {
		debouncedSearch(value)
	}

	const onDateChange = (date: any, dateString: any) => {
		setParams({
			...params,
			CreatedStart: dateString[0],
			CreatedEnd: dateString[1],
			PageNumber: 1,
		})
	}

	const onPageChange = (newPage: number, newPageSize?: number) => {
		setParams((prevParams) => ({
			...prevParams,
			PageNumber: newPage,
			PageSize: newPageSize || prevParams.PageSize,
		}))
	}

	const downloadXLSX = async () => {
		try {
			dispatch(loadingStatus(true))
			const { data, headers } =
				await appealsAPI.downloadAccountRequestsReport(params)

			const contentDisposition = headers['content-disposition']

			let fileName = `отчет заявки на счет от ${new Date().toLocaleDateString('ru')}.xlsx`

			if (contentDisposition) {
				const fileNameMatch = contentDisposition.match(
					/filename\*?=(["']?)(.*?)\1/i
				)
				if (fileNameMatch && fileNameMatch[2]) {
					fileName = decodeURIComponent(fileNameMatch[2])
				}
			}

			const blob = new Blob([data])
			const link = document.createElement('a')
			link.href = URL.createObjectURL(blob)
			link.download = fileName
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
			URL.revokeObjectURL(link.href)
		} catch (e) {
			console.error(e)
			openErrorNotification('Ошибка открытия документа')
		} finally {
			dispatch(loadingStatus(false))
		}
	}

	return (
		<div className="readings">
			<div className="readings__title">
				<p>Список заявок на счет</p>
				<Button onClick={() => downloadXLSX()}>Скачать</Button>
			</div>
			<div className="readings__filter">
				<div className="readings__filter-text">Фильтр по дате:</div>
				<RangePicker onChange={onDateChange} />
				<div className="readings__filter-text">Поиск:</div>
				<Search
					placeholder="Поиск"
					onSearch={onSearch}
					style={{ width: 200 }}
				/>
			</div>
			<Table
				dataSource={applicationsAccount?.appeals}
				columns={columns}
				pagination={false}
				rowKey={(abonent) => abonent.id}
			/>
			<Pagination
				className="readings__pagination"
				current={params.PageNumber}
				align={'end'}
				pageSize={params.PageSize}
				total={applicationsAccount?.totalElementCount}
				onChange={onPageChange}
			/>
		</div>
	)
}
