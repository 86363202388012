import { Button, Input } from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useAppSelector } from 'store/hooks'

import { openErrorNotification, openSuccessNotification } from '@/utils'

import { terminalAPI } from '@/api'

type DayKey =
	| 'monday'
	| 'tuesday'
	| 'wednesday'
	| 'thursday'
	| 'friday'
	| 'saturday'

type FormValues = Record<
	DayKey,
	{
		start: string
		end: string
	}
>

const dayOfWeekMap: Record<DayKey, number> = {
	monday: 1,
	tuesday: 2,
	wednesday: 3,
	thursday: 4,
	friday: 5,
	saturday: 6,
}

const dayLabels: Record<DayKey, string> = {
	monday: 'Пн',
	tuesday: 'Вт',
	wednesday: 'Ср',
	thursday: 'Чт',
	friday: 'Пт',
	saturday: 'Сб',
}

interface DefaultWorkTimeProps {
	id: string
}

export default function DefaultWorkTime({ id }: DefaultWorkTimeProps) {
	const currentTerminal = useAppSelector(
		(state) => state.terminals.currentTerminal
	)
	const [isSaving, setIsSaving] = useState(false)

	const getDefaultValues = (): FormValues => {
		const initialValues = Object.keys(dayOfWeekMap).reduce(
			(acc, day) => ({
				...acc,
				[day as DayKey]: { start: '', end: '' },
			}),
			{} as FormValues
		)

		if (!currentTerminal?.defaultWorkTimeDays) return initialValues

		currentTerminal.defaultWorkTimeDays.forEach((day: any) => {
			const dayKey = Object.entries(dayOfWeekMap).find(
				([, num]) => num === day.day
			)?.[0] as DayKey | undefined

			if (dayKey) {
				initialValues[dayKey] = {
					start: day.startTime ? day.startTime : '',
					end: day.endTime ? day.endTime : '',
				}
			}
		})

		return initialValues
	}

	const {
		control,
		formState: { errors },
		handleSubmit,
		trigger,
		watch,
	} = useForm<FormValues>({
		mode: 'onChange',
		defaultValues: getDefaultValues(),
	})

	const validateTime = (day: DayKey) => {
		const values = watch(day)
		const bothFilled = !!values.start && !!values.end
		const bothEmpty = !values.start && !values.end

		return (
			bothEmpty ||
			(bothFilled &&
				dayjs(values.start, 'HH:mm').isBefore(dayjs(values.end, 'HH:mm')))
		)
	}

	const onSubmit = async (data: FormValues) => {
		setIsSaving(true)
		try {
			const isValid = await trigger()

			if (!isValid) {
				openErrorNotification('Исправьте ошибки в расписании')
				return
			}

			const updates = Object.entries(data)
				.filter(([_, times]) => times.start && times.end)
				.map(([day, times]) => ({
					dayOfWeek: dayOfWeekMap[day as DayKey],
					startWorkTime: dayjs(times.start, 'HH:mm').format('HH:mm:ss'),
					endWorkTime: dayjs(times.end, 'HH:mm').format('HH:mm:ss'),
					terminalId: id,
				}))

			if (updates.length === 0) {
				openErrorNotification('Заполните хотя бы один день полностью')
				return
			}

			await Promise.all(
				updates.map((params) => terminalAPI.defaultWorkTimeDay(params))
			)

			openSuccessNotification('Расписание обновлено!')
		} catch (e) {
			console.error('Ошибка сохранения:', e)
			openErrorNotification('Ошибка при сохранении')
		} finally {
			setIsSaving(false)
		}
	}

	return (
		<div className="work-time">
			<div className="work-time__title _h4">Стандартное расписание работы</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="work-time__days-container">
					{Object.entries(dayLabels).map(([day, label]) => {
						const hasError = errors[day as DayKey]
						return (
							<div
								key={day}
								className={`work-time__day ${
									hasError ? 'work-time__day--error' : ''
								}`}
							>
								<div
									className={`work-time__day-label ${
										hasError ? 'work-time__day-label--error' : ''
									}`}
								>
									{label}
								</div>
								<div className="work-time__inputs">
									<Controller
										name={`${day as DayKey}.start`}
										control={control}
										rules={{ validate: () => validateTime(day as DayKey) }}
										render={({ field }) => (
											<Input
												size="small"
												type="time"
												status={hasError ? 'error' : ''}
												{...field}
											/>
										)}
									/>
									<Controller
										name={`${day as DayKey}.end`}
										control={control}
										rules={{ validate: () => validateTime(day as DayKey) }}
										render={({ field }) => (
											<Input
												size="small"
												type="time"
												status={hasError ? 'error' : ''}
												{...field}
											/>
										)}
									/>
								</div>
							</div>
						)
					})}
				</div>

				<Button
					type="primary"
					htmlType="submit"
					loading={isSaving}
					disabled={isSaving}
					className="work-time__submit-button"
				>
					Сохранить расписание
				</Button>
			</form>
		</div>
	)
}
