import { Tabs, TabsProps } from 'antd'
import EmailConfig from 'components/EmailConfig/EmailConfig'
import DefaultWorkTimeTerminals from 'components/Settings/DefaultWorkTimeTerminals'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

const items: TabsProps['items'] = [
	{
		key: '1',
		label: 'Почта',
		children: (
			<div className="administration__inner">
				<EmailConfig />
			</div>
		),
	},
	{
		key: '2',
		label: 'График работы',
		children: (
			<div className="administration__inner">
				<DefaultWorkTimeTerminals />
			</div>
		),
	},
]

export function SettingsPage() {
	const [searchParams, setSearchParams] = useSearchParams()
	const initialTab = searchParams.get('tab') || '1'
	const [activeTab, setActiveTab] = useState(initialTab)

	const handleTabChange = (key: string) => {
		setActiveTab(key)
		setSearchParams({ tab: key })
	}

	return (
		<div className="settings">
			<div className="settings__title title">Настройки</div>
			<Tabs
				activeKey={activeTab}
				onChange={handleTabChange}
				items={items}
				type="card"
				size="large"
				className="reports__tabs"
			/>
		</div>
	)
}
