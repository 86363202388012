import AppLayout from 'layout/AppLayout'
import MainLayout from 'layout/MainLayout'
import { createBrowserRouter } from 'react-router-dom'

import { Error } from '@/components'

import {
	Administration,
	Login,
	ReportsPage,
	SettingsPage,
	TerminalPage,
	TerminalsPage,
	UserPage,
} from '@/pages'

import {
	ADMIN_PATH,
	ERROR_PATH,
	MAIN_PATH,
	OPERATOR_PATH,
	REPORTS_PATH,
	SETTINGS_PATH,
	TERMINALS_PATH,
} from '@/utils'

import { ProtectedRoute } from './protectedRoute'

const router = createBrowserRouter(
	[
		{
			path: MAIN_PATH,
			element: <Login />,
			errorElement: <Error />,
		},
		{
			path: MAIN_PATH,
			element: <AppLayout />,
			errorElement: <Error />,
			children: [
				{
					path: MAIN_PATH,
					element: <MainLayout />,
					errorElement: <Error />,

					children: [
						{
							path: TERMINALS_PATH,
							element: (
								<ProtectedRoute allowedRoles={['Admin', 'User']}>
									<TerminalsPage />
								</ProtectedRoute>
							),
							errorElement: <Error />,
						},
						{
							path: `${TERMINALS_PATH}/:id`,
							element: (
								<ProtectedRoute allowedRoles={['Admin', 'User']}>
									<TerminalPage />
								</ProtectedRoute>
							),
							errorElement: <Error />,
						},
						{
							path: ADMIN_PATH,
							element: (
								<ProtectedRoute allowedRoles={['Admin']}>
									<Administration />
								</ProtectedRoute>
							),
							errorElement: <Error />,
						},
						{
							path: `${OPERATOR_PATH}/:id`,
							element: (
								<ProtectedRoute allowedRoles={['Admin']}>
									<UserPage />
								</ProtectedRoute>
							),
							errorElement: <Error />,
						},
						{
							path: REPORTS_PATH,
							element: (
								<ProtectedRoute allowedRoles={['Admin', 'Statistician']}>
									<ReportsPage />
								</ProtectedRoute>
							),
							errorElement: <Error />,
						},
						{
							path: SETTINGS_PATH,
							element: (
								<ProtectedRoute allowedRoles={['Admin']}>
									<SettingsPage />
								</ProtectedRoute>
							),
							errorElement: <Error />,
						},
					],
				},
			],
		},

		{
			path: ERROR_PATH,
			element: <Error />,
		},
	],
	{
		future: {
			v7_fetcherPersist: true,
			v7_normalizeFormMethod: true,
			v7_partialHydration: true,
			v7_relativeSplatPath: true,
			v7_skipActionErrorRevalidation: true,
		},
	}
)

export default router
