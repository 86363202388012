import { SearchOutlined } from '@ant-design/icons'
import {
	Button,
	DatePicker,
	Input,
	InputRef,
	Pagination,
	Space,
	Table,
	TableColumnType,
	TableProps,
} from 'antd'
import { FilterDropdownProps } from 'antd/es/table/interface'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { loadingStatus } from 'store/app'
import { useAppDispatch } from 'store/hooks'

import { getReadingsParams, openErrorNotification } from '@/utils'

import { abonentAPI, terminalAPI } from '@/api'

type OnChange = NonNullable<TableProps<any>['onChange']>
const { RangePicker } = DatePicker

export const Readings = () => {
	const searchInput = useRef<InputRef>(null)
	const [terminalsNames, setTerminalsNames] = useState<any>([])
	const [dataList, setDataList] = useState<
		undefined | { readings: Array<any>; totalElementCount: number }
	>(undefined)
	const dispatch = useAppDispatch()
	const [params, setParams] = useState<getReadingsParams>({
		PageNumber: 1,
		PageSize: 10,
	})

	const fetchData = async () => {
		dispatch(loadingStatus(true))
		try {
			const { data } = await abonentAPI.getReadings({ ...params })
			const TerminalNames = await terminalAPI.getNames()
			setTerminalsNames(TerminalNames.data)
			setDataList(data)
		} catch (e) {
			console.error(e)
		} finally {
			dispatch(loadingStatus(false))
		}
	}

	const handleSearch = (
		selectedKeys: string[],
		confirm: FilterDropdownProps['confirm'],
		dataIndex: any
	) => {
		confirm()

		setParams((prev) => ({
			...prev,
			PageNumber: 1,
			SearchStringPersonalAcc: selectedKeys[0],
		}))
	}

	const handleReset = (clearFilters: () => void) => {
		clearFilters()
	}

	const getColumnSearchProps = (dataIndex: any): TableColumnType<any> => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
			close,
		}) => (
			<div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
				<Input
					ref={searchInput}
					placeholder={`Поиск ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) =>
						setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					onPressEnter={() =>
						handleSearch(selectedKeys as string[], confirm, dataIndex)
					}
					style={{ marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() =>
							handleSearch(selectedKeys as string[], confirm, dataIndex)
						}
						size="small"
						style={{ width: 90 }}
					>
						Фильтр
					</Button>
					<Button
						onClick={() => clearFilters && handleReset(clearFilters)}
						size="small"
						style={{ width: 90 }}
					>
						Сбросить
					</Button>

					<Button
						type="link"
						size="small"
						onClick={() => {
							close()
						}}
					>
						Закрыть
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered: boolean) => (
			<SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
		),
		filterDropdownProps: {
			onOpenChange(open) {
				if (open) {
					setTimeout(() => searchInput.current?.select(), 100)
				}
			},
		},
	})

	const columns = [
		{
			title: 'Лицевой счет',
			dataIndex: 'abonent',
			key: 'abonentPerconalAcc',
			render: (e: any) => <span>{e?.personalAcc}</span>,
			...getColumnSearchProps('abonentPerconalAcc'),
		},
		{
			title: 'Имя терминала',
			dataIndex: 'terminal',
			key: 'terminal',
			render: (e: any) => <span>{e?.name}</span>,
			filters: terminalsNames?.map((e: any) => ({
				value: e,
				text: e,
			})),
			filterSearch: true,
		},
		{
			title: 'Дата и время',
			render: (e: any) => (
				<div>{moment(e.createdAt).format('YYYY-MM-DD, HH:mm:ss')}</div>
			),
		},
		{
			title: 'Показания',
			dataIndex: 'reading',
			key: 'reading',
		},
		{
			title: 'В реестре',
			dataIndex: 'isSynced',
			key: 'isSynced',
			render: (e: any) => <span>{e ? 'Да' : 'Нет'}</span>,
		},
	]

	const onDateChange = (date: any, dateString: any) => {
		setParams({
			...params,
			StartedAt: dateString[0],
			EndedAt: dateString[1],
			PageNumber: 1,
		})
	}

	const onPageChange = (newPage: number, newPageSize?: number) => {
		setParams((prevParams) => ({
			...prevParams,
			PageNumber: newPage,
			PageSize: newPageSize || prevParams.PageSize,
		}))
	}

	const handleChange: OnChange = (_, filters) => {
		setParams((prev) => ({
			...prev,
			PageNumber: 1,
			TerminalNames: filters.terminal,
			SearchStringPersonalAcc: filters.abonentPerconalAcc
				? filters.abonentPerconalAcc[0]
				: undefined,
		}))
	}

	useEffect(() => {
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params])

	const downloadXLSX = async () => {
		try {
			dispatch(loadingStatus(true))
			const { data, headers } = await abonentAPI.downloadReadings(params)

			const contentDisposition = headers['content-disposition']

			let fileName = `отчет список показаний от ${new Date().toLocaleDateString('ru')}.xlsx`

			if (contentDisposition) {
				const fileNameMatch = contentDisposition.match(
					/filename\*?=(["']?)(.*?)\1/i
				)
				if (fileNameMatch && fileNameMatch[2]) {
					fileName = decodeURIComponent(fileNameMatch[2])
				}
			}

			const blob = new Blob([data])
			const link = document.createElement('a')
			link.href = URL.createObjectURL(blob)
			link.download = fileName
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
			URL.revokeObjectURL(link.href)
		} catch (e) {
			console.error(e)
			openErrorNotification('Ошибка открытия документа')
		} finally {
			dispatch(loadingStatus(false))
		}
	}

	return (
		<div className="readings">
			<div className="readings__title">
				<p>Список показаний</p>
				<Button onClick={() => downloadXLSX()}>Скачать</Button>
			</div>
			<div className="readings__filter">
				<div className="readings__filter-text">Фильтр по дате:</div>
				<RangePicker onChange={onDateChange} />
			</div>
			<Table
				dataSource={dataList?.readings}
				columns={columns}
				rowKey="id"
				pagination={false}
				onChange={handleChange}
			/>
			<Pagination
				className="readings__pagination"
				current={params.PageNumber}
				pageSize={params.PageSize}
				total={dataList?.totalElementCount}
				onChange={onPageChange}
			/>
		</div>
	)
}
