import { Button, Input, notification } from 'antd'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { requiredField } from '@/utils'

import { devicesAPI } from '@/api'

interface Props {
	terminalId: string
}

interface form {
	counter: number
}

export const SettingPrinterModal = ({ terminalId }: Props) => {
	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm<form>({
		defaultValues: {
			counter: 0,
		},
	})

	const onSubmit: SubmitHandler<form> = (data) => {
		console.log(data)
	}
	const handleTestPrint = async () => {
		try {
			await devicesAPI.testPrinting({
				terminalId,
				typePrinter: 1,
			})
			notification.success({ message: 'Успешно' })
		} catch (error) {
			console.error(error)
			notification.error({ message: 'Произошла ошибка' })
		}
	}

	return (
		<div className="setting-printer">
			<form onSubmit={handleSubmit(onSubmit)} className="setting-printer__form">
				<div className="setting-printer__form-item">
					<div className="setting-printer__form-item-title">
						Счетчик страниц
					</div>
					<Controller
						name="counter"
						control={control}
						rules={requiredField}
						render={({ field }) => (
							<Input
								type="number"
								id="counter"
								className="setting-printer__form-item-input"
								disabled
								{...field}
							/>
						)}
					/>
					{errors.counter && (
						<div className="input__error">
							{errors.counter.message || 'Ошибка'}
						</div>
					)}
				</div>
				<Button
					className="setting-printer__btn"
					type="primary"
					htmlType="submit"
				>
					Сбросить счетчик
				</Button>
			</form>
			<Button className="setting-printer__btn" onClick={handleTestPrint}>
				Тест печати
			</Button>
		</div>
	)
}
