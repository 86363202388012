import { Button, Modal } from 'antd'
import { useState } from 'react'

import { statusDevice } from '@/utils'

import { SettingAcquiringModal, SettingPrinterModal } from '../modal'
import { SettingPrinterKKMModal } from '../modal/SettingPrinterKKMModal'

interface Props {
	TerminalId?: string
	title: string
	status: any
	icon?: string
	setting?: boolean
	statusMessage?: string | null
}

export const Equipment = ({
	TerminalId,
	title,
	status,
	setting,
	icon,
	statusMessage,
}: Props) => {
	const [openSettingPrinter, setOpenSettingPrinter] = useState<boolean>(false)
	const [openSettingPrinterKKM, setOpenSettingPrinterKKM] =
		useState<boolean>(false)
	const [openSettingAcquiring, setOpenSettingAcquiring] =
		useState<boolean>(false)

	return (
		<>
			<div className="device" data-name={title}>
				<p className="device__title">
					{title} <img src={icon} alt="terminal__device-btn" />
				</p>
				<p
					className={`device__status device__status--${status === 1 ? 'success' : 'error'}`}
				>
					{statusDevice[status!]}
				</p>
				{statusMessage && status === 3 && (
					<p
						className={`device__status _small device__status--${status === 1 ? 'success' : 'error'}`}
					>
						{statusMessage}
					</p>
				)}

				{setting && (
					<Button
						onClick={() => {
							if (title === 'Принтер') {
								setOpenSettingPrinter(true)
							}
							if (title === 'Эквайринг') {
								setOpenSettingAcquiring(true)
							}
							if (title === 'KKM') {
								setOpenSettingPrinterKKM(true)
							}
						}}
					>
						Настроить
					</Button>
				)}
			</div>
			<Modal
				title={`Настройка принтера`}
				open={openSettingPrinter}
				onOk={() => setOpenSettingPrinter(false)}
				onCancel={() => setOpenSettingPrinter(false)}
				footer={null}
			>
				<SettingPrinterModal terminalId={TerminalId!} />
			</Modal>
			<Modal
				title={`Настройка принтера ККМ`}
				open={openSettingPrinterKKM}
				onOk={() => setOpenSettingPrinterKKM(false)}
				onCancel={() => setOpenSettingPrinterKKM(false)}
				footer={null}
			>
				<SettingPrinterKKMModal terminalId={TerminalId!} />
			</Modal>
			{openSettingAcquiring && (
				<Modal
					title={`Настройка эквайринга`}
					open={openSettingAcquiring}
					onOk={() => setOpenSettingAcquiring(false)}
					onCancel={() => setOpenSettingAcquiring(false)}
					footer={null}
				>
					<SettingAcquiringModal TerminalId={TerminalId!} />
				</Modal>
			)}
		</>
	)
}
