import { terminalCallAPI } from 'api/api'
import { useEffect } from 'react'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'

import { Loading } from '@/components'

import {
	setAppealId,
	setCallRecordId,
	setIsChecking,
	setTypeCall,
	useAppDispatch,
	useAppSelector,
} from '@/store'

import { MAIN_PATH } from '@/utils'

const AppLayout = () => {
	const { loading, init } = useAppSelector((state) => state.app)
	const { isAuth } = useAppSelector((state) => state.auth)
	const { isChecking } = useAppSelector((state) => state.call)
	const dispatch = useAppDispatch()
	const router = useNavigate()

	useEffect(() => {
		const checkCall = async () => {
			try {
				const { data } = await terminalCallAPI.check_user_is_in_call()
				if (data.isInCall && data.appealId && data.terminalId) {
					dispatch(setAppealId(data.appealId))
					dispatch(setCallRecordId(data.callRecordId))
					dispatch(setTypeCall('operator'))
					router(`/terminals/${data.terminalId}?session=${data.appealId}`, {
						replace: true,
					})
				} else {
					const searchParams = new URLSearchParams(window.location.search)
					searchParams.delete('session')
					const newQuery = searchParams.toString()
					const newPath = `${window.location.pathname}${newQuery ? '?' + newQuery : ''}`
					router(newPath, { replace: true })
				}
			} catch (error) {
				console.error(error)
			} finally {
				dispatch(setIsChecking(true))
			}
		}

		if (!isChecking) {
			checkCall()
		}
	}, [])

	if (!init) {
		return <Loading />
	}

	if (!isAuth) {
		return <Navigate to={MAIN_PATH} />
	}

	return (
		<>
			{loading ? <Loading /> : ''}
			<Outlet />
		</>
	)
}

export default AppLayout
