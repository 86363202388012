import { Button, DatePicker, Modal, Pagination, Table, TableProps } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { loadingStatus } from 'store/app'
import { useAppDispatch } from 'store/hooks'

import { getFeedBackListParams, openErrorNotification } from '@/utils'

import { terminalAPI, terminalCallAPI, usersAPI } from '@/api'

type OnChange = NonNullable<TableProps<any>['onChange']>
const { RangePicker } = DatePicker

interface Terminal {
	id: string
	name: string
	terminalId: string
	currentIP: string
	address: string
	astipStatus: string
	isMaintenance: boolean
}

interface Operator {
	id: string
	userName: string
	email: string
	role: string
	isActive: boolean
	isBlocked: boolean
	isInCall: boolean
	location: any
	photoId: any
}

interface CallRecord {
	terminal: Terminal
	operator: Operator
	abonent: any
	documents: any
	id: string
	dateTimeStart: string
	dateTimeEnd?: string
	callDuration?: number
	status: string
	direction: number
}

export const CallLogList = () => {
	const [terminalsNames, setTerminalsNames] = useState<any>([])
	const [operators, setOperators] = useState<any>([])
	const [statuses, setStatuses] = useState<any>([])
	const [dataList, setDataList] = useState<
		undefined | { callRecords: Array<CallRecord>; totalElementCount: number }
	>(undefined)
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [modalData, setModalData] = useState<CallRecord | null>(null)

	const dispatch = useAppDispatch()
	const [params, setParams] = useState<getFeedBackListParams>({
		PageNumber: 1,
		PageSize: 10,
	})

	const fetchData = async () => {
		try {
			dispatch(loadingStatus(true))
			const { data } = await terminalCallAPI.get_call_records({ ...params })
			setDataList(data)
			const TerminalNames = await terminalAPI.getNames()
			const users = await usersAPI.getUsers({})
			const statusList = await terminalCallAPI.getStatus()
			setStatuses(
				statusList.data.map((e: any) => ({
					value: e.id,
					text: e.status,
				}))
			)
			setOperators(
				users.data
					.filter((e: any) => e.role === 'User' || e.role === 'Admin')
					.map((e: any) => e.userName)
			)
			setTerminalsNames(TerminalNames.data)
		} catch (e) {
			console.error(e)
		} finally {
			dispatch(loadingStatus(false))
		}
	}

	const columns = [
		{
			title: 'Имя терминала',
			key: 'terminalName',
			filterSearch: true,
			filters: terminalsNames?.map((e: any) => ({
				value: e,
				text: e,
			})),
			render: (e: any) => <span>{e?.terminal?.name}</span>,
		},
		{
			title: 'Лицевой счет',
			key: 'abonentPersonalAcc',
			render: (e: any) => (
				<span>{e?.abonent?.personalAcc ? e.abonent.personalAcc : '-'}</span>
			),
		},
		{
			title: 'Время звонка',
			key: 'dateTimeStart',
			render: (e: any) =>
				e.dateTimeEnd ? (
					<div>{`${moment(e.dateTimeStart).format('YYYY-MM-DD, HH:mm:ss')} - ${moment(e.dateTimeEnd).format('HH:mm:ss')}`}</div>
				) : (
					<div>{`${moment(e.dateTimeStart).format('YYYY-MM-DD, HH:mm:ss')}`}</div>
				),
		},
		{
			title: 'Длительность',
			key: 'callDuration',
			render: (e: any) => (
				<div>{moment.utc(e.callDuration * 1000).format('m:ss')} мин</div>
			),
		},
		{
			title: 'Оператор',
			key: 'operator',
			filterSearch: true,
			render: (e: any) => <span>{e?.operator?.userName}</span>,
			filters: operators?.map((e: any) => ({
				value: e,
				text: e,
			})),
		},
		{
			title: 'Документы в рамках сеанса',
			key: 'documents',
			render: (e: any) =>
				e?.documents?.length ? (
					<button
						className="_highlight"
						onClick={() => e?.documents?.length && openModal(e)}
					>
						{e?.documents?.length}
					</button>
				) : (
					0
				),
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			filters: statuses,
			filterMultiple: false,
		},
	]

	const openModal = (record: CallRecord) => {
		setModalData(record)
		setIsModalVisible(true)
	}

	const closeModal = () => {
		setIsModalVisible(false)
		setModalData(null)
	}

	useEffect(() => {
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params])

	const onDateChange = (date: any, dateString: any) => {
		setParams({
			...params,
			StartedAt: dateString[0],
			EndedAt: dateString[1],
			PageNumber: 1,
		})
	}

	const onPageChange = (newPage: number, newPageSize?: number) => {
		setParams((prevParams) => ({
			...prevParams,
			PageNumber: newPage,
			PageSize: newPageSize || prevParams.PageSize,
		}))
	}

	const handleChange: OnChange = (_, filters) => {
		setParams((prev) => ({
			...prev,
			PageNumber: 1,
			TerminalNames: filters.terminalName,
			OperatorNames: filters.operator,
			CallStatus: filters?.status ? filters.status[0] : undefined,
		}))
	}

	const downloadXLSX = async () => {
		try {
			dispatch(loadingStatus(true))
			const { data, headers } =
				await terminalCallAPI.downloadCallRecords(params)

			const contentDisposition = headers['content-disposition']

			let fileName = `отчет журнал вызовов от ${new Date().toLocaleDateString('ru')}.xlsx`

			if (contentDisposition) {
				const fileNameMatch = contentDisposition.match(
					/filename\*?=(["']?)(.*?)\1/i
				)
				if (fileNameMatch && fileNameMatch[2]) {
					fileName = decodeURIComponent(fileNameMatch[2])
				}
			}

			const blob = new Blob([data])
			const link = document.createElement('a')
			link.href = URL.createObjectURL(blob)
			link.download = fileName
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
			URL.revokeObjectURL(link.href)
		} catch (e) {
			console.error(e)
			openErrorNotification('Ошибка открытия документа')
		} finally {
			dispatch(loadingStatus(false))
		}
	}

	return (
		<div className="readings">
			<div className="readings__title">
				<p>Список обратных звонков</p>
				<Button onClick={() => downloadXLSX()}>Скачать</Button>
			</div>
			<div className="readings__filter">
				<div className="readings__filter-text">Фильтр по дате:</div>
				<RangePicker onChange={onDateChange} />
			</div>
			<Table
				dataSource={dataList?.callRecords}
				columns={columns}
				pagination={false}
				rowKey="id"
				onChange={handleChange}
			/>
			<Pagination
				className="readings__pagination"
				current={params.PageNumber}
				pageSize={params.PageSize}
				total={dataList?.totalElementCount}
				onChange={onPageChange}
			/>
			<Modal
				title="Информация о документах"
				visible={isModalVisible}
				onCancel={closeModal}
				footer={null}
			>
				{modalData && (
					<div>
						<p>
							<strong>Документы:</strong>{' '}
							{modalData?.documents?.map((doc: string, idx: number) => (
								<div key={idx}>{doc}</div>
							))}
						</p>
					</div>
				)}
			</Modal>
		</div>
	)
}
