import { Button, DatePicker, Pagination, Table, TableProps } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { loadingStatus } from 'store/app'
import { useAppDispatch } from 'store/hooks'

import { getFeedBackListParams, openErrorNotification } from '@/utils'

import { terminalAPI } from '@/api'

const { RangePicker } = DatePicker

type OnChange = NonNullable<TableProps<any>['onChange']>
type feedbackType = {
	id: string
	paymentTerminalId: string
	shopId: string
	sum: number
	terminalId: string
	dateCreation: string
	terminalName: string
	isMatchingResults: boolean
}

export const ReceiptsList = () => {
	const [dataList, setDataList] = useState<
		undefined | { receipts: Array<feedbackType>; totalElementCount: number }
	>(undefined)

	const dispatch = useAppDispatch()
	const [params, setParams] = useState<getFeedBackListParams>({
		PageNumber: 1,
		PageSize: 10,
	})

	const fetchData = async () => {
		try {
			dispatch(loadingStatus(true))
			const { data } = await terminalAPI.getListReceipts({ ...params })
			setDataList(data)
		} catch (e) {
			console.error(e)
		} finally {
			dispatch(loadingStatus(false))
		}
	}

	const columns = [
		{
			title: 'Дата',
			render: (e: any) => (
				<div>{moment(e.dateCreation).format('YYYY-MM-DD, HH:mm:ss')}</div>
			),
		},
		{
			title: 'Id терминала оплаты',
			dataIndex: 'paymentTerminalId',
			key: 'paymentTerminalId',
		},
		{
			title: 'Id shop',
			dataIndex: 'shopId',
			key: 'shopId',
		},
		{
			title: 'Сумма',
			dataIndex: 'sum',
			key: 'sum',
		},

		{
			title: 'Имя терминала',
			dataIndex: 'terminalName',
			key: 'terminalName',
		},
		{
			title: 'Совпадают результаты',
			render: (e: any) => <div>{e.isMatchingResults ? 'да' : 'нет'}</div>,
		},
	]

	useEffect(() => {
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params])

	const handleChange: OnChange = (_, filters) => {
		setParams((prev) => ({
			...prev,
			PageNumber: 1,
			TerminalNames: filters.terminalName,
		}))
	}

	const onDateChange = (date: any, dateString: any) => {
		setParams({
			...params,
			StartedAt: dateString[0],
			EndedAt: dateString[1],
			PageNumber: 1,
		})
	}

	const onPageChange = (newPage: number, newPageSize?: number) => {
		setParams((prevParams) => ({
			...prevParams,
			PageNumber: newPage,
			PageSize: newPageSize || prevParams.PageSize,
		}))
	}

	const downloadXLSX = async () => {
		try {
			dispatch(loadingStatus(true))
			const { data, headers } = await terminalAPI.downloadReceipts(params)

			const contentDisposition = headers['content-disposition']

			let fileName = `отчет сверка итогов от ${new Date().toLocaleDateString('ru')}.xlsx`

			if (contentDisposition) {
				const fileNameMatch = contentDisposition.match(
					/filename\*?=(["']?)(.*?)\1/i
				)
				if (fileNameMatch && fileNameMatch[2]) {
					fileName = decodeURIComponent(fileNameMatch[2])
				}
			}

			const blob = new Blob([data])
			const link = document.createElement('a')
			link.href = URL.createObjectURL(blob)
			link.download = fileName
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
			URL.revokeObjectURL(link.href)
		} catch (e) {
			console.error(e)
			openErrorNotification('Ошибка открытия документа')
		} finally {
			dispatch(loadingStatus(false))
		}
	}

	return (
		<div className="readings">
			<div className="readings__title">
				<p>Список чеков сверки итогов</p>
				<Button onClick={() => downloadXLSX()}>Скачать</Button>
			</div>
			<div className="readings__filter">
				<div className="readings__filter-text">Фильтр по дате:</div>
				<RangePicker onChange={onDateChange} />
			</div>

			<Table
				dataSource={dataList?.receipts}
				columns={columns}
				pagination={false}
				rowKey="id"
				onChange={handleChange}
			/>
			<Pagination
				className="readings__pagination"
				current={params.PageNumber}
				pageSize={params.PageSize}
				total={dataList?.totalElementCount}
				onChange={onPageChange}
			/>
		</div>
	)
}
