import { ScreenSaversType } from 'utils/types'

import {
	addAppeals,
	editUserType,
	getAccountRequestsReportType,
	getAppeals,
	getCallRecords,
	getFeedbacks,
	getPaymentsListType,
	getReadingsListType,
	newUserType,
} from '../utils/types/commonType'
import { getUsersType } from '../utils/types/storeType'

import { axiosPrivate, axiosPrivateFile } from './axios'

export const backgroundVideosAPI = {
	getScreenSavers() {
		return axiosPrivate.get<ScreenSaversType[]>('videos/get_screensavers')
	},
	uploadScreenSaversVideoById({
		terminalId,
		videoId,
	}: {
		terminalId: string
		videoId: string
	}) {
		return axiosPrivate.post('videos/upload_advertisement_screensaver_arm', {
			terminalId,
			videoId,
		})
	},
	uploadScreenSavers({ UploadedFile }: { UploadedFile: any }) {
		let data = new FormData()
		data.append('UploadedFile', UploadedFile)
		return axiosPrivate.post('videos/load_screensaver', data)
	},
	loadAdvertisement({
		AdType,
		UploadedFile,
	}: {
		AdType: 1 | 2 | 3
		UploadedFile: File
	}) {
		const myData = new FormData()
		myData.append('AdType', AdType.toString())
		myData.append('UploadedFile', UploadedFile)
		return axiosPrivateFile.post(`/videos/load_advertisement`, myData)
	},
	getAdvertisement({ type }: { type: 1 | 2 | 3 }) {
		return axiosPrivate.get<ScreenSaversType[]>(
			`/videos/get_advertisements?type=${type}`
		)
	},
	uploadAdvertisementScreensaverArm(body: {
		terminalId: string
		videoId: string
	}) {
		return axiosPrivate.post(
			`/videos//upload_advertisement_screensaver_arm`,
			body
		)
	},
}

export const authAPI = {
	login(login: string, password: string) {
		return axiosPrivate.post('auth/login', {
			login,
			password,
		})
	},

	refreshToken({
		accessToken,
		refreshToken,
	}: {
		accessToken: string
		refreshToken: string
	}) {
		return axiosPrivate.post('auth/refresh_token', {
			accessToken,
			refreshToken,
		})
	},
}

export const terminalAPI = {
	getList({ UserId }: { UserId?: string }) {
		return axiosPrivate.get(
			`terminals/get_terminals${UserId ? `?UserId=${UserId}` : ''}`
		)
	},

	getTerminalInfo({ TerminalId }: { TerminalId: string }) {
		return axiosPrivate.get(
			`terminals/get_terminal_info?TerminalId=${TerminalId}`
		)
	},
	getNames() {
		return axiosPrivate.get(`teminals/get_names`)
	},

	getUserTerminals() {
		return axiosPrivate.get(`terminals/get_user_terminals`)
	},

	setUserTerminals({
		userId,
		terminalsId,
	}: {
		userId: string
		terminalsId: Array<string>
	}) {
		return axiosPrivate.patch(`terminals/set_user_terminals`, {
			userId,
			terminalsId,
		})
	},

	getTerminalConfig({ TerminalId }: { TerminalId: string }) {
		return axiosPrivate.get(`terminals/get_config?TerminalId=${TerminalId}`)
	},

	getStatusList() {
		return axiosPrivate.get(`terminals/status_list`)
	},

	deleteTerminal({ Id }: { Id: string }) {
		return axiosPrivate.delete(`terminals/delete_terminal?Id=${Id}`)
	},

	addNewterminal({
		name,
		currentIP,
		userId,
		astipConfigId,
	}: {
		name: string
		currentIP: string
		userId?: string
		astipConfigId?: string
	}) {
		return axiosPrivate.post('terminals/add_terminal', {
			name,
			currentIP,
			...(userId && { userId }),
			...(astipConfigId && { astipConfigId }),
		})
	},

	addNewStatus({ id, status }: { id: string; status: number }) {
		return axiosPrivate.patch(`terminals/set_status_terminal`, {
			id,
			status,
		})
	},
	editTerminal({
		id,
		name,
		currentIP,
		astipConfigId,
		address,
	}: {
		id: string
		name: string
		currentIP: string
		astipConfigId?: string
		address?: string
	}) {
		return axiosPrivate.patch(`terminals/edit_terminal`, {
			id,
			name,
			currentIP,
			...(astipConfigId && { astipConfigId }),
			...(address && { address }),
		})
	},
	printDoc({
		terminalId,
		namePrinter,
		file,
	}: {
		terminalId: string
		namePrinter: string
		file: string
	}) {
		const myData = new FormData()
		myData.append('file', file)
		myData.append('terminalId', terminalId)
		myData.append('namePrinter', namePrinter)
		return axiosPrivateFile.post(`terminals/print_doc`, myData)
	},
	scanDoc({ terminalId }: { terminalId: string }) {
		return axiosPrivate.get(
			`terminals/read_scanned_doc?terminalId=${terminalId}`,
			{
				responseType: 'blob',
			}
		)
	},
	saveScanDoc({
		terminalId,
		appealId,
		name,
	}: {
		terminalId: string
		appealId: string | null
		name: string
	}) {
		return axiosPrivate.post(`terminals/save_scanned_docs`, {
			terminalId,
			appealId,
			nameFile: name,
		})
	},
	removeScanDoc({ terminalId }: { terminalId: string }) {
		return axiosPrivate.patch(`terminals/remove_scanned_docs`, {
			terminalId,
		})
	},
	moveCamera({
		terminalId,
		direction,
		duration = 1,
	}: {
		terminalId: string
		direction: number
		duration?: number
	}) {
		return axiosPrivate.post(`terminals/move_camera`, {
			terminalId,
			direction,
			duration,
		})
	},
	getDocuments({
		TerminalId,
		AppealId,
	}: {
		TerminalId: string
		AppealId?: string
	}) {
		return axiosPrivate.get(`terminals/get_documnets`, {
			params: {
				TerminalId,
				AppealId,
			},
		})
	},
	getDocument({ DocumentId }: { DocumentId: string }) {
		return axiosPrivate.get(
			`terminals/get_url_document?DocumentId=${DocumentId}`,
			{
				responseType: 'blob',
			}
		)
	},
	deleteQuery({
		TerminalId,
		IdElement,
	}: {
		TerminalId: string
		IdElement: number
	}) {
		return axiosPrivate.delete(
			`terminals/delete_query_element?TerminalId=${TerminalId}&IdElement=${IdElement}`
		)
	},
	getDateExclusions({
		TerminalId,
		DateStart,
		DateEnd,
	}: {
		TerminalId: string
		DateStart: string
		DateEnd: string
	}) {
		return axiosPrivate.get(
			`terminals/get_exclusions_work_time?TerminalId=${TerminalId}&DateStart=${DateStart}&DateEnd=${DateEnd}`
		)
	},
	terminalsDefaultWorkTime({
		terminalIds,
		daysOfWeek,
	}: {
		terminalIds: string[]
		daysOfWeek: any[]
	}) {
		return axiosPrivate.patch('terminals/terminals_default_work_time', {
			terminalIds,
			daysOfWeek,
		})
	},
	defaultWorkTimeDay(body: {
		dayOfWeek: number
		startWorkTime: string
		endWorkTime: string
		terminalId: string
	}) {
		return axiosPrivate.patch(`terminals/default_work_time_day`, body)
	},
	addDateExclusions({
		terminalId,
		date,
		DateStart,
		DateEnd,
	}: {
		terminalId: string
		date: string
		DateStart?: string
		DateEnd?: string
	}) {
		let startEndTimesData = null
		if (DateStart) {
			startEndTimesData = {
				...(DateStart && { item1: DateStart }),
				...(DateEnd && { item2: DateEnd }),
			}
		}
		return axiosPrivate.post(`terminals/add_exclusion_work_time`, {
			terminalId,
			date,
			startEndTimes: startEndTimesData,
		})
	},
	removeDateExclusions({ ExclusionId }: { ExclusionId: string }) {
		return axiosPrivate.delete(
			`terminals/delete_exclusion_work_time?ExclusionId=${ExclusionId}`
		)
	},
	switchMaintenance({ terminalId }: { terminalId: string }) {
		return axiosPrivate.post(`terminals/switch_maintenance_flag`, {
			terminalId,
		})
	},
	switchDisabledStatus({
		id,
		isDisabled,
	}: {
		id: string
		isDisabled: boolean
	}) {
		return axiosPrivate.patch(`terminals/switch_disabled_status`, {
			id,
			isDisabled,
		})
	},
	setDefaultWorkTime({
		terminalId,
		startWorkTime,
		endWorkTime,
	}: {
		terminalId: string
		startWorkTime: string
		endWorkTime: string
	}) {
		return axiosPrivate.patch(`terminals/set_default_work_time`, {
			terminalId,
			startWorkTime,
			endWorkTime,
		})
	},
	getStatusWorkTime({ TerminalId }: { TerminalId: string }) {
		return axiosPrivate.get(
			`terminals/terminal_status_for_work_time?TerminalId=${TerminalId}`
		)
	},
	getConfig({ TerminalId }: { TerminalId: string }) {
		return axiosPrivate.get(`terminals/get_config`, {
			responseType: 'blob',
			params: {
				TerminalId,
			},
		})
	},
	setConfig({ TerminalId, Config }: { TerminalId: string; Config: File }) {
		const myData = new FormData()
		myData.append('TerminalId', TerminalId)
		myData.append('Config', Config)
		return axiosPrivate.patch(`terminals/set_config`, myData)
	},
	getListReceipts({ StartedAt, EndedAt, PageNumber, PageSize }: getFeedbacks) {
		return axiosPrivate.get(`terminals/reconciliation_receipts`, {
			params: {
				DateStart: StartedAt,
				DateEnd: EndedAt,
				PageNumber,
				PageSize,
			},
		})
	},
	downloadReceipts({ StartedAt, EndedAt, PageNumber, PageSize }: getFeedbacks) {
		return axiosPrivate.get(`terminals/reconciliation_receipts/download`, {
			params: {
				DateStart: StartedAt,
				DateEnd: EndedAt,
			},
			responseType: 'blob',
		})
	},
	setQr(data: { terminalId: string; type: number; qrCode: string }) {
		return axiosPrivate.post(`terminals/set_qrc_inf_sec`, data)
	},
}
export const journalsAPI = {
	getJournals({
		id,
		DateTimeStart,
		DateTimeEnd,
		EventType,
		Importance,
		PageNumber,
		PageSize,
	}: {
		id: string
		DateTimeStart?: string
		DateTimeEnd?: string
		EventType?: string
		Importance?: string
		PageNumber?: number
		PageSize?: number
	}) {
		let paramStroke = `id=${id}`
		if (DateTimeStart) {
			paramStroke += `&DateTimeStart=${DateTimeStart}`
		}
		if (DateTimeEnd) {
			paramStroke += `&DateTimeEnd=${DateTimeEnd}`
		}
		if (EventType?.length) {
			paramStroke += `&EventType=${EventType}`
		}
		if (Importance?.length) {
			paramStroke += `&Importance=${Importance}`
		}
		if (PageNumber) {
			paramStroke += `&PageNumber=${PageNumber}`
		}
		if (PageSize) {
			paramStroke += `&PageSize=${PageSize}`
		}
		return axiosPrivate.get(`journals/get_astip_journal?${paramStroke}`)
	},
	getImportance() {
		return axiosPrivate.get(`appeals/importance_list`)
	},
	getEventType() {
		return axiosPrivate.get(`appeals/event_type_list`)
	},
}

export const terminalCallAPI = {
	accept_call() {
		return axiosPrivate.patch(`terminal_call/accept_call`)
	},
	delete_call({ UserId }: { UserId: string }) {
		return axiosPrivate.patch(
			`terminal_call/delete_call`,
			{},
			{
				params: {
					UserId,
				},
			}
		)
	},
	getStatus() {
		return axiosPrivate.get(`terminal_call/status_list`)
	},
	get_call_records({
		StartedAt,
		EndedAt,
		PageNumber,
		PageSize,
		SearchString,
		TerminalNames,
		OperatorNames,
		CallStatus,
	}: getCallRecords) {
		return axiosPrivate.get(`terminal_call/get_call_records`, {
			params: {
				StartedAt,
				EndedAt,
				PageNumber,
				PageSize,
				SearchString,
				CallStatus,
				TerminalNames,
				OperatorNames,
			},
			paramsSerializer: {
				indexes: true,
			},
		})
	},
	reject_call() {
		return axiosPrivate.patch(`terminal_call/reject_call`)
	},
	end_call() {
		return axiosPrivate.patch(`terminal_call/end_call`)
	},
	receive_operator_call(terminalId: string) {
		return axiosPrivate.post(`terminal_call/receive_operator_call`, {
			terminalId,
		})
	},
	check_user_is_in_call() {
		return axiosPrivate.get(`terminal_call/check_user_is_in_call`)
	},
	downloadCallRecords({
		StartedAt,
		EndedAt,
		PageNumber,
		PageSize,
		SearchString,
		TerminalNames,
		OperatorNames,
		CallStatus,
	}: getCallRecords) {
		return axiosPrivate.get(`terminal_call/get_call_records/download`, {
			params: {
				StartedAt,
				EndedAt,
				PageNumber,
				PageSize,
				SearchString,
				CallStatus,
				TerminalNames,
				OperatorNames,
			},
			paramsSerializer: {
				indexes: true,
			},
			responseType: 'blob',
		})
	},
}

export const usersAPI = {
	getUsers({ UserName, Email, IsActive, Role, IsBlocked }: getUsersType) {
		let stroke = ''
		const object = {
			...(UserName && { UserName }),
			...(Email && { Email }),
			...(IsActive && { IsActive }),
			...(Role && { Role }),
			...(IsBlocked && { IsBlocked }),
		}
		for (const key in object) {
			//@ts-ignore
			stroke += `${key}=${object[key]}&`
		}

		return axiosPrivate.get(`users/get_users${stroke ? `?${stroke}` : ''}`)
	},
	getUser({ UserId }: { UserId: string }) {
		return axiosPrivate.get(`users/get_user_info?UserId=${UserId}`)
	},
	addUser({ userName, login, password, role, email }: newUserType) {
		return axiosPrivate.post(`users/add`, {
			userName,
			login,
			password,
			role,
			email,
		})
	},
	getFoto({ PhotoId }: { PhotoId: string }) {
		return axiosPrivate.get(`users/user_photo?PhotoId=${PhotoId}`, {
			responseType: 'blob',
		})
	},
	changeFoto({ userId, photo }: { userId: string; photo: any }) {
		const myData = new FormData()
		myData.append('Photo', photo)
		myData.append('UserId', userId)
		return axiosPrivate.post(`users/change_photo`, myData)
	},
	deletePhoto({ appUserId }: { appUserId: string }) {
		return axiosPrivate.delete(`users/delete_photo`, {
			params: {
				appUserId,
			},
		})
	},
	switchStatus(IsActive: boolean) {
		return axiosPrivate.patch(
			`users/switch_status`,
			{},
			{
				params: {
					IsActive,
				},
			}
		)
	},
	banUser({ UserId }: { UserId: string }) {
		return axiosPrivate.patch(`users/ban?UserId=${UserId}`)
	},
	unBanUser({ UserId }: { UserId: string }) {
		return axiosPrivate.patch(`users/unban?UserId=${UserId}`)
	},
	updateUser({ userName, login, id, email }: editUserType) {
		return axiosPrivate.post(`users/edit`, {
			userName,
			login,
			id,
			email,
		})
	},
	newPassUser({ UserId, password }: { UserId: string; password: string }) {
		return axiosPrivate.patch(`users/set_new_password`, {
			password,
			userId: UserId,
		})
	},
	getUsersLocations() {
		return axiosPrivate.get(`users/get_users_locations`)
	},
	setUsersLocations({
		userId,
		locationId,
	}: {
		userId: string
		locationId: number
	}) {
		return axiosPrivate.patch(`users/set_user_location`, { userId, locationId })
	},
}

export const appealsAPI = {
	getList({
		Type,
		Status,
		CreatedStart,
		AbonentId,
		AstipId,
		UserId,
		CreatedEnd,
		PageNumber,
		PageSize,
	}: getAppeals) {
		return axiosPrivate.get(`appeals/list`, {
			params: {
				Type,
				Status,
				CreatedStart,
				AbonentId,
				AstipId,
				UserId,
				CreatedEnd,
				PageNumber,
				PageSize,
			},
		})
	},
	getStatutList() {
		return axiosPrivate.get(`appeals/status_list`)
	},
	getTypesList() {
		return axiosPrivate.get(`appeals/types_list`)
	},
	addAppeals({ type, status, comment, abonentId, terminalId }: addAppeals) {
		return axiosPrivate.post(`appeals/add`, {
			type,
			status,
			comment,
			abonentId,
			terminalId,
		})
	},
	getAppeals(AppealId: string) {
		return axiosPrivate.get(`appeals/get_info?AppealId=${AppealId}`)
	},
	getPaymentsList({
		TerminalNames,
		TerminalId,
		StartedAt,
		EndedAt,
		PersonalAcc,
		Status,
		PaymentType,
		PageNumber,
		PageSize,
		IsSuccess,
		SearchStringPersonalAcc,
	}: getPaymentsListType) {
		return axiosPrivate.get(`appeals/get_payments_list`, {
			params: {
				TerminalNames,
				TerminalId,
				StartedAt,
				EndedAt,
				PersonalAcc,
				Status,
				PaymentType,
				PageNumber,
				PageSize,
				SearchStringPersonalAcc,
				IsSuccess,
			},
			paramsSerializer: {
				indexes: true,
			},
		})
	},
	downloadPaymentsList({
		TerminalNames,
		TerminalId,
		StartedAt,
		EndedAt,
		PersonalAcc,
		Status,
		PaymentType,
		PageNumber,
		PageSize,
		IsSuccess,
		SearchStringPersonalAcc,
	}: getPaymentsListType) {
		return axiosPrivate.get(`appeals/get_payments_list/download`, {
			params: {
				TerminalNames,
				TerminalId,
				StartedAt,
				EndedAt,
				PersonalAcc,
				Status,
				PaymentType,
				PageNumber,
				PageSize,
				SearchStringPersonalAcc,
				IsSuccess,
			},
			paramsSerializer: {
				indexes: true,
			},
			responseType: 'blob',
		})
	},
	addDocument({
		appealId,
		documentId,
	}: {
		appealId: string
		documentId: string
	}) {
		return axiosPrivate.post(`appeals/add_document`, {
			appealId,
			documentId,
		})
	},
	setComment({
		appealId,
		comment,
	}: {
		appealId: string
		comment: string | null
	}) {
		return axiosPrivate.patch(`appeals/set_comment`, {
			appealId,
			comment,
		})
	},
	setStatus({
		appealId,
		appealStatus,
	}: {
		appealId: string
		appealStatus: number
	}) {
		return axiosPrivate.patch(`appeals/set_status`, {
			appealId,
			appealStatus,
		})
	},
	editAfterCall({
		appealId,
		abonentPersonalAcc,
	}: {
		appealId: string
		abonentPersonalAcc?: string | null
	}) {
		return axiosPrivate.patch(`appeals/edit_after_call`, {
			appealId,
			abonentPersonalAcc,
		})
	},
	getAccountRequestsReport({
		PageNumber = 1,
		PageSize,
		CreatedEnd,
		CreatedStart,
		SearchString,
	}: getAccountRequestsReportType) {
		return axiosPrivate.get('appeals/account_requests_report', {
			params: {
				PageNumber,
				PageSize,
				CreatedEnd,
				CreatedStart,
				SearchString,
			},
		})
	},
	downloadAccountRequestsReport({
		CreatedEnd,
		CreatedStart,
		SearchString,
	}: getAccountRequestsReportType) {
		return axiosPrivate.get(`appeals/account_requests_report/download`, {
			params: {
				CreatedEnd,
				CreatedStart,
				SearchString,
			},
			responseType: 'blob',
		})
	},
	getListFeedBack({
		StartedAt,
		EndedAt,
		PageNumber,
		PageSize,
		SearchStringPersonalAcc,
		TerminalNames,
	}: getFeedbacks) {
		return axiosPrivate.get(`appeals/get_feedbacks`, {
			params: {
				StartedAt,
				EndedAt,
				PageNumber,
				PageSize,
				SearchStringPersonalAcc,
				TerminalNames,
			},
			paramsSerializer: {
				indexes: true,
			},
		})
	},
	downloadListFeedBack({
		StartedAt,
		EndedAt,
		PageNumber,
		PageSize,
		SearchStringPersonalAcc,
		TerminalNames,
	}: getFeedbacks) {
		return axiosPrivate.get(`appeals/get_feedbacks/download`, {
			params: {
				StartedAt,
				EndedAt,
				PageNumber,
				PageSize,
				SearchStringPersonalAcc,
				TerminalNames,
			},
			responseType: 'blob',
		})
	},
	getPaymentReceipt({ PaymentId }: { PaymentId: string }) {
		return axiosPrivate.get(
			`appeals/get_payment_receipt?PaymentId=${PaymentId}`,
			{
				responseType: 'blob',
			}
		)
	},
}

export const abonentAPI = {
	addAbonent({
		personalAcc,
		email,
		phoneNumber,
	}: {
		personalAcc: string
		email: string
		phoneNumber: string
	}) {
		return axiosPrivate.post(`abonents/add`, {
			personalAcc,
			email,
			phoneNumber,
		})
	},
	getAbonent({ Filter }: { Filter?: string }) {
		let stroke = ''
		const object = {
			...(Filter && { Filter }),
		}
		for (const key in object) {
			//@ts-ignore
			stroke += `${key}=${object[key]}&`
		}
		return axiosPrivate.get(`abonents/get_list${stroke ? `?${stroke}` : ''}`)
	},
	getReadings({
		TerminalNames,
		TerminalId,
		StartedAt,
		EndedAt,
		PageNumber,
		PageSize,
		PersonalAcc,
		PaymentType,
		SearchStringPersonalAcc,
	}: getReadingsListType) {
		return axiosPrivate.get(`abonents/get_readings`, {
			params: {
				SearchStringPersonalAcc,
				TerminalId,
				StartedAt,
				PageNumber,
				PageSize,
				EndedAt,
				PersonalAcc,
				PaymentType,
				TerminalNames,
			},
			paramsSerializer: {
				indexes: true,
			},
		})
	},
	downloadReadings({
		TerminalNames,
		TerminalId,
		StartedAt,
		EndedAt,
		PageNumber,
		PageSize,
		PersonalAcc,
		PaymentType,
		SearchStringPersonalAcc,
	}: getReadingsListType) {
		return axiosPrivate.get(`abonents/get_readings/download`, {
			params: {
				SearchStringPersonalAcc,
				TerminalId,
				StartedAt,
				PageNumber,
				PageSize,
				EndedAt,
				PersonalAcc,
				PaymentType,
				TerminalNames,
			},
			paramsSerializer: {
				indexes: true,
			},
			responseType: 'blob',
		})
	},
}

export const devicesAPI = {
	get_shift_status({ TerminalId }: { TerminalId: string }) {
		return axiosPrivate.get(`/devices/get_shift_status`, {
			params: {
				TerminalId,
			},
		})
	},
	open_shift({ TerminalId }: { TerminalId: string }) {
		return axiosPrivate.patch(
			`/devices/open_shift`,
			{},
			{
				params: {
					TerminalId,
				},
			}
		)
	},
	close_shift({ TerminalId }: { TerminalId: string }) {
		return axiosPrivate.patch(
			`/devices/close_shift`,
			{},
			{
				params: {
					TerminalId,
				},
			}
		)
	},
	testPrinting({
		terminalId,
		typePrinter,
	}: {
		terminalId: string
		typePrinter: 1 | 3
	}) {
		return axiosPrivate.post(`/devices/test_printring`, {
			terminalId,
			typePrinter,
		})
	},
}
export const licensesAPI = {
	getInfo() {
		return axiosPrivate.get(`/licenses/get_info`)
	},
	uploadInfo({ File }: { File: any }) {
		let data = new FormData()
		data.append('File', File)
		return axiosPrivate.post(`/licenses/license_verify`, data)
	},
}

export const emailConfigAPI = {
	getEmailConfig() {
		return axiosPrivate.get(`/email_config/get`)
	},
	editEmailConfig({
		senderAddress,
		host,
		port,
		password,
		paymentsAddress,
		readingsAddress,
		callbacksAddress,
	}: {
		senderAddress: string
		host: string
		port: number
		password: string
		paymentsAddress: string
		readingsAddress: string
		callbacksAddress: string
	}) {
		return axiosPrivate.patch(`/email_config/edit`, {
			senderAddress,
			host,
			port,
			password,
			paymentsAddress,
			readingsAddress,
			callbacksAddress,
		})
	},
}
